import React, { useState, useEffect, useRef } from "react";
import update from "immutability-helper";

import axios from "axios";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import { validate2to128AlphaNumeric, validateAccountName, validateTagKey } from "../../../../helpers/validator";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/monitoring/monitoringtools";

const FILE_SIZE_LIMIT_IN_BYTE = 1000000;
const UPLOAD_FOLDER = "templates";

const getInitialFormData = (data) => {
  const initialFormData = {
    deployintegrationwithmonitoringtools: {
      value: data[`${PREFIX}/deployintegrationwithmonitoringtools`] || "yes",
    },
    appdynamics_deployintegrationwithappdynamics: {
      value:
        data[`${PREFIX}/appdynamics/deployintegrationwithappdynamics`] || "yes",
    },
    appdynamics_controllerinfoxmlfiles3key: {
      value: data[`${PREFIX}/appdynamics/controllerinfoxmlfiles3key`] || "",
      isValid: data[`${PREFIX}/appdynamics/controllerinfoxmlfiles3key`]
        ? true
        : false,
    },
    appdynamics_apikey: {
      value: data[`${PREFIX}/appdynamics/apikey`] || "",
      isValid: data[`${PREFIX}/appdynamics/apikey`] ? true : false,
    },
    datadog_deployintegrationwithdatadog: {
      value: data[`${PREFIX}/datadog/deployintegrationwithdatadog`] || "yes",
    },
    datadog_accountid: {
      value: data[`${PREFIX}/datadog/accountid`] || "",
      isValid: data[`${PREFIX}/datadog/accountid`] ? true : false,
    },
    datadog_applicationkey: {
      value: data[`${PREFIX}/datadog/applicationkey`] || "",
      isValid: data[`${PREFIX}/datadog/applicationkey`] ? true : false,
    },
    datadog_apikey: {
      value: data[`${PREFIX}/datadog/apikey`] || "",
      isValid: data[`${PREFIX}/datadog/apikey`] ? true : false,
    },
    signalfx_deployintegrationwithsignalfx: {
      value: data[`${PREFIX}/signalfx/deployintegrationwithsignalfx`] || "yes",
    },
    signalfx_accountid: {
      value: data[`${PREFIX}/signalfx/accountid`] || "",
      isValid: data[`${PREFIX}/signalfx/accountid`] ? true : false,
    },
    signalfx_externalid: {
      value: data[`${PREFIX}/signalfx/externalid`] || "",
      isValid: data[`${PREFIX}/signalfx/externalid`] ? true : false,
    },
  };

  return initialFormData;
};

const MonitoringToolsIntegration = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));
  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  const [xmlFile, setXmlFile] = useState(null);
  const [existingXmlFile, setExistingXmlFile] = useState(null);
  const fileInputRef = useRef(null);

  const [showDDAccountId, setShowDDAccountId] = useState(false);
  const [showDDAppKey, setShowDDAppKey] = useState(false);
  const [showDDApiKey, setShowDDApiKey] = useState(false);
  const [showADApiKey, setShowADApiKey] = useState(false);
  const [showSFXAccId, setShowSFXAccId] = useState(false);
  const [showSFXExtId, setShowSFXExtId] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        setEmpty(result.data.empty);
        setFormData(convertBEToFE(result.data));

        if (result.data && result.data[`${PREFIX}/appdynamics/controllerinfoxmlfiles3key`]) {
          setXmlFile({
            name: result.data[`${PREFIX}/appdynamics/controllerinfoxmlfiles3key`],
          });
          setExistingXmlFile({
            name: result.data[`${PREFIX}/appdynamics/controllerinfoxmlfiles3key`],
          });
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);

      let xmlFileName = null;

      if (xmlFile && xmlFile.size < FILE_SIZE_LIMIT_IN_BYTE &&
        formData.appdynamics_deployintegrationwithappdynamics.value === "yes") {
        const initiateUploadResult = await awsAmplify.initiateUpload(
          projectId,
          UPLOAD_FOLDER,
          xmlFile.name
        );

        const uploadResult = await axios.put(
          initiateUploadResult.data.signedUrl,
          xmlFile,
          {
            headers: {
              "Content-Type": xmlFile.type,
            },
          }
        );

        console.log(uploadResult);

        if (uploadResult.status === 200) {
          xmlFileName = xmlFile.name;
        }
      }

      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(xmlFileName),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data);

    return fe;
  };

  const convertFEToBE = (xmlFileName) => {
    const be = {
      [`${PREFIX}/deployintegrationwithmonitoringtools`]: formData
        .deployintegrationwithmonitoringtools.value,
    };

    if (formData.deployintegrationwithmonitoringtools.value === "yes") {
      be[`${PREFIX}/appdynamics/deployintegrationwithappdynamics`] =
        formData.appdynamics_deployintegrationwithappdynamics.value;

      if (
        formData.appdynamics_deployintegrationwithappdynamics.value === "yes"
      ) {
        if (xmlFileName) {
          be[
            `${PREFIX}/appdynamics/controllerinfoxmlfiles3key`
          ] = `${xmlFileName}`;
        } else {
          if (existingXmlFile) {
            be[
              `${PREFIX}/appdynamics/controllerinfoxmlfiles3key`
            ] = `${existingXmlFile.name}`;
          }
        }

        if (formData.appdynamics_apikey.isValid) {
          be[`${PREFIX}/appdynamics/apikey`] =
            formData.appdynamics_apikey.value;
        }
      }

      be[`${PREFIX}/datadog/deployintegrationwithdatadog`] =
        formData.datadog_deployintegrationwithdatadog.value;

      if (formData.datadog_deployintegrationwithdatadog.value === "yes") {
        if (formData.datadog_accountid.isValid) {
          be[`${PREFIX}/datadog/accountid`] = formData.datadog_accountid.value;
        }

        if (formData.datadog_applicationkey.isValid) {
          be[`${PREFIX}/datadog/applicationkey`] =
            formData.datadog_applicationkey.value;
        }
        if (formData.datadog_apikey.isValid) {
          be[`${PREFIX}/datadog/apikey`] =
            formData.datadog_apikey.value;
        }
      }

      be[`${PREFIX}/signalfx/deployintegrationwithsignalfx`] =
        formData.signalfx_deployintegrationwithsignalfx.value;

      if (formData.signalfx_deployintegrationwithsignalfx.value === "yes") {
        if (formData.signalfx_accountid.isValid) {
          be[`${PREFIX}/signalfx/accountid`] =
            formData.signalfx_accountid.value;
        }

        if (formData.signalfx_externalid.isValid) {
          be[`${PREFIX}/signalfx/externalid`] =
            formData.signalfx_externalid.value;
        }
      }
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const onFieldChange = (value, key) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (validateFunc, value, key) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          [key]: { isValid: { $set: errorMsg === undefined } },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, { [key]: { isValid: { $set: false } } })
      );
    }
  };

  const exists = (key, value) => {
    return formData[key].indexOf(value) !== -1;
  };


  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Monitoring</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Monitoring"
                isDirty={isDirty}
                //dashboardRoute={ROUTES.GOVERNANCE_SERVICE_CONTROL}
                designRoute={ROUTES.DESIGN_MONITORING}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3>Monitoring Tools Integration</h3>
                  </div>
                  <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                        Deploy Integration with Monitoring Tools
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <RadioGroup
                          field="deployintegrationwithmonitoringtools"
                          value={
                            formData.deployintegrationwithmonitoringtools.value
                          }
                          onChange={updateRadio}
                          isLarge={true}
                        ></RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>

                {empty && (
                  <NoInitialData />
                )}

                {formData.deployintegrationwithmonitoringtools.value ===
                  "yes" && (
                  <div className="row show-hide-content">
                    <div className="mainContentArea row px-2 py-1 my-1">
                      <div className="col-12 d-flex flex-column px-1">
                        <div className="formRow my-1 row w-100">
                          <div className="one col-lg-3 showRightContentBtn-v3 col-md-6 col-12 my-1 form-line d-inline-flex align-items-center">
                            <label className="switch mb-0">
                              <input
                                type="checkbox"
                                checked={
                                  formData
                                    .appdynamics_deployintegrationwithappdynamics
                                    .value === "yes"
                                }
                                onChange={(e) => {
                                  setIsDirty(true);
                                  const value = e.target.checked;

                                  setFormData((state) =>
                                    update(state, {
                                      appdynamics_deployintegrationwithappdynamics: {
                                        value: {
                                          $set: value ? "yes" : "no",
                                        },
                                      },
                                    })
                                  );
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                            <span className="switchLabel">
                              Deploy Integration with AppDynamics{" "}
                            </span>
                          </div>
                          <div className="rightContentArea col-md-9">
                          {formData.appdynamics_deployintegrationwithappdynamics.value === "yes" && (
                            <>
                          <div className="rightContent-v3-area col-lg-5 col-md-6 my-1 col-12 ">
                            <div className="rightContent-v3">
                              <div className="justify-content-end d-inline-flex w-100  mr-0 pr-0">
                                <label
                                  className="d-inline-flex mb-0 align-items-center"
                                  htmlFor="upload-xml2"
                                >
                                  <span className="mr-1 text-color-blue">
                                    Upload Controller Info XML File
                                  </span>
                                  <img src="../images/upload.svg" />
                                </label>
                                <input
                                  type="file"
                                  style={{ width: "5px", visibility: "hidden" }}
                                  name="xml"
                                  className="form-control bg-transparent"
                                  id="upload-xml2"
                                  ref={fileInputRef}
                                  onChange={(e) => {
                                    const file = e.target.files[0];

                                    if (file.size > FILE_SIZE_LIMIT_IN_BYTE) {
                                      setErrorMsg(
                                        `${file.name} has exceeded ${FILE_SIZE_LIMIT_IN_BYTE} Bytes`
                                      );
                                      return;
                                    }

                                    if (file.type !== "text/xml") {
                                      setErrorMsg("Only XML file is allowed");
                                      return;
                                    }

                                    const newFileName = "monitoring-monitoringtools-appdynamics-controllerinfo.xml";
                                    var blob = file.slice(0, file.size, file.type); 
                                    var newFile = new File([blob], newFileName, {type: ''});
                                    setXmlFile(newFile);
                                   // setXmlFile(file);

                                    setIsDirty(true);
                                  }}
                                />
                                <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                  <button
                                    disabled={disabled || !xmlFile}
                                    className="closableInput"
                                    onClick={() => {
                                      setIsDirty(true);
                                      setXmlFile(null);
                                      setExistingXmlFile(null);
                                    }}
                                  ></button>
                                  <input
                                    placeholder={
                                      xmlFile
                                        ? xmlFile.name.split('-').pop()
                                        : "Sample.xml"
                                    }
                                    type="text"
                                    disabled
                                    className="form-control bg-white"
                                    onClick={() => fileInputRef.current.click()}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="rightContent-v3-area col-lg-4 my-1 col-md-6 col-12  ">
                            <div className="rightContent-v3">
                              <div className="d-inline-flex w-100 justify-content-end">
                                <span className="font-12 d-flex align-items-center mr-3">
                                  API Key
                                </span>
                                <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                  <button disabled={disabled}
                                    type="button"
                                    onClick={() => setShowADApiKey(!showADApiKey)}
                                    className="showableInput"
                                  ></button>
                                  <input
                                    placeholder=""
                                    type={showADApiKey ? "text" : "password"}
                                    className={`form-control bg-white ${
                                      formData.appdynamics_apikey.value !==
                                        "" &&
                                      formData.appdynamics_apikey.isValid ===
                                        false
                                        ? "invalid"
                                        : ""
                                    }`}
                                    value={formData.appdynamics_apikey.value}
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "appdynamics_apikey"
                                      )
                                    }
                                    onBlur={(e) =>
                                      onFieldBlur(
                                        validateAccountName,
                                        e.target.value,
                                        "appdynamics_apikey"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                            </>) }
                            </div>
                       </div>

                        <div className="formRow my-1 row w-100 justify-content-start">
                          <div className="one col-lg-3 showRightContentBtn-v3 col-md-6 col-12 my-1 form-line d-inline-flex align-items-center">
                            <label className="switch mb-0">
                              <input
                                type="checkbox"
                                checked={
                                  formData.datadog_deployintegrationwithdatadog
                                    .value === "yes"
                                }
                                onChange={(e) => {
                                  setIsDirty(true);
                                  const value = e.target.checked;

                                  setFormData((state) =>
                                    update(state, {
                                      datadog_deployintegrationwithdatadog: {
                                        value: {
                                          $set: value ? "yes" : "no",
                                        },
                                      },
                                    })
                                  );
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                            <span className="switchLabel">
                              Deploy Integration with Datadog{" "}
                            </span>
                          </div>

                          {formData.datadog_deployintegrationwithdatadog.value === "yes" && (
                            <>
                          <div className="rightContent-v3-area col-lg-3 col-md-6 my-1 col-12 ">
                            <div className="rightContent-v3">
                              <div className="justify-content-end d-inline-flex w-100  mr-0 pr-0">
                                <span className="font-12 d-flex align-items-center mr-3">
                                  Datadog Account Id
                                </span>
                                <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                  <button disabled={disabled}
                                    type="button"
                                    onClick={() => setShowDDAccountId(!showDDAccountId)}
                                    className="showableInput"
                                  ></button>
                                  <input
                                    placeholder=""
                                    type={showDDAccountId ? "text" : "password"}
                                    disabled=""
                                    className={`form-control bg-white ${
                                      formData.datadog_accountid.value !== "" &&
                                      formData.datadog_accountid.isValid ===
                                        false
                                        ? "invalid"
                                        : ""
                                    }`}
                                    value={formData.datadog_accountid.value}
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "datadog_accountid"
                                      )
                                    }
                                    onBlur={(e) =>
                                      onFieldBlur(
                                        validateAccountName,
                                        e.target.value,
                                        "datadog_accountid"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="rightContent-v3-area col-lg-3 my-1 col-md-6 col-12  ">
                            <div className="rightContent-v3">
                              <div className="d-inline-flex w-100 justify-content-end">
                                <span className="font-12 d-flex align-items-center mr-3">
                                  Application Key
                                </span>
                                <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                  <button disabled={disabled}
                                    type="button"
                                    onClick={() => setShowDDAppKey(!showDDAppKey)}
                                    className="showableInput"
                                  ></button>
                                  <input
                                    placeholder=""
                                    type={showDDAppKey ? "text" : "password"}
                                    disabled=""
                                    className={`form-control bg-white ${
                                      formData.datadog_applicationkey.value !==
                                        "" &&
                                      formData.datadog_applicationkey.isValid ===
                                        false
                                        ? "invalid"
                                        : ""
                                    }`}
                                    value={formData.datadog_applicationkey.value}
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "datadog_applicationkey"
                                      )
                                    }
                                    onBlur={(e) =>
                                      onFieldBlur(
                                        validateTagKey,
                                        e.target.value,
                                        "datadog_applicationkey"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                       
                          <div className="rightContent-v3-area col-lg-3 my-1 col-md-6 col-12  ">
                            <div className="rightContent-v3">
                              <div className="d-inline-flex w-100 justify-content-end">
                                <span className="font-12 d-flex align-items-center mr-3">
                                  API Key
                                </span>
                                <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                  <button disabled={disabled}
                                    type="button"
                                    onClick={() => setShowDDApiKey(!showDDApiKey)}
                                    className="showableInput"
                                  ></button>
                                  <input
                                    placeholder=""
                                    type={showDDApiKey ? "text" : "password"}
                                    disabled=""
                                    className={`form-control bg-white ${
                                      formData.datadog_apikey.value !==
                                        "" &&
                                      formData.datadog_apikey.isValid ===
                                        false
                                        ? "invalid"
                                        : ""
                                    }`}
                                    value={formData.datadog_apikey.value}
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "datadog_apikey"
                                      )
                                    }
                                    onBlur={(e) =>
                                      onFieldBlur(
                                        validateTagKey,
                                        e.target.value,
                                        "datadog_apikey"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                           </>)}
                         </div>
                        <div className="formRow my-1 row w-100 justify-content-start">
                          <div className="one col-lg-3 showRightContentBtn-v3 col-md-6 col-12 my-1 form-line d-inline-flex align-items-center">
                            <label className="switch mb-0">
                              <input
                                type="checkbox"
                                checked={
                                  formData
                                    .signalfx_deployintegrationwithsignalfx
                                    .value === "yes"
                                }
                                onChange={(e) => {
                                  setIsDirty(true);
                                  const value = e.target.checked;

                                  setFormData((state) =>
                                    update(state, {
                                      signalfx_deployintegrationwithsignalfx: {
                                        value: {
                                          $set: value ? "yes" : "no",
                                        },
                                      },
                                    })
                                  );
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                            <span className="switchLabel">
                              Deploy Integration with Signal FX{" "}
                            </span>
                          </div>
                          
                          {formData.signalfx_deployintegrationwithsignalfx.value === "yes" && (
                            <>
                          <div className="rightContent-v3-area col-lg-5 col-md-6 my-1 col-12 ">
                            <div className="rightContent-v3">
                              <div className="justify-content-end d-inline-flex w-100  mr-0 pr-0">
                                <span className="font-12 d-flex align-items-center mr-3">
                                  SignalFX Account Id
                                </span>
                                <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                  <button disabled={disabled}
                                    type="button"
                                    onClick={() => setShowSFXAccId(!showSFXAccId)}
                                    className="showableInput"
                                  ></button>
                                  <input
                                    placeholder=""
                                    type={showSFXAccId ? "text" : "password"}
                                    disabled=""
                                    className={`form-control bg-white ${
                                      formData.signalfx_accountid.value !==
                                        "" &&
                                      formData.signalfx_accountid.isValid ===
                                        false
                                        ? "invalid"
                                        : ""
                                    }`}
                                    value={formData.signalfx_accountid.value}
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "signalfx_accountid"
                                      )
                                    }
                                    onBlur={(e) =>
                                      onFieldBlur(
                                        validateAccountName,
                                        e.target.value,
                                        "signalfx_accountid"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="rightContent-v3-area col-lg-4 my-1 col-md-6 col-12  ">
                            <div className="rightContent-v3">
                              <div className="d-inline-flex w-100 justify-content-end">
                                <span className="font-12 d-flex align-items-center mr-3">
                                  External Id
                                </span>
                                <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                  <button disabled={disabled}
                                    type="button"
                                    onClick={() => setShowSFXExtId(!showSFXExtId)}
                                    className="showableInput"
                                  ></button>
                                  <input
                                    placeholder=""
                                    type={showSFXExtId ? "text" : "password"}
                                    disabled=""
                                    className={`form-control bg-white ${
                                      formData.signalfx_externalid.value !==
                                        "" &&
                                      formData.signalfx_externalid.isValid ===
                                        false
                                        ? "invalid"
                                        : ""
                                    }`}
                                    value={formData.signalfx_externalid.value}
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "signalfx_externalid"
                                      )
                                    }
                                    onBlur={(e) =>
                                      onFieldBlur(
                                        validateAccountName,
                                        e.target.value,
                                        "signalfx_externalid"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                           </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
               )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.MONITORING_LOG_ANALYTICS + "/" + projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Log Analytics
                    </p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MonitoringToolsIntegration;
