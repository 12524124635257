import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";
import { validateCidr } from "../../../../helpers/validator";

const PREFIX = "/aws/governance/scps";

const getInitialFormData = (data) => {
  const initialFormData = {
    deploybaselinescps: {
      value: data[`${PREFIX}/deploybaselinescps`] || "no",
    },
    preventdisablingcoregovernanceservices: {
      value: data[`${PREFIX}/preventdisablingcoregovernanceservices`] || "yes",
    },
    limitregions: {
      value: data[`${PREFIX}/limitregions`] || "yes",
    },
    disallowactionsasrootuser: {
      value: data[`${PREFIX}/disallowactionsasrootuser`] || "yes",
    },
    disallowcreationofaccesskeysforrootuser: {
      value: data[`${PREFIX}/disallowcreationofaccesskeysforrootuser`] || "yes",
    },
    preventdeletionofvpcflowlogs: {
      value: data[`${PREFIX}/preventdeletionofvpcflowlogs`] || "yes",
    },
    preventawsaccountsfromleavingtheorg: {
      value: data[`${PREFIX}/preventawsaccountsfromleavingtheorg`] || "yes",
    },
    restrictaccessbasedonclientcidrrange: {
      value: data[`${PREFIX}/restrictaccessbasedonclientcidrrange`] || "yes",
    },
    cidrblocks: data[
      `${PREFIX}/restrictaccessbasedonclientcidrrange/cidrblocks`
    ]
      ? data[`${PREFIX}/restrictaccessbasedonclientcidrrange/cidrblocks`] === "none" ? [{ value: "", isValid: false }] : (data[`${PREFIX}/restrictaccessbasedonclientcidrrange/cidrblocks`].split(
          ","
        ).map(cidr => {return {value: cidr, isValid: true}}))
      : [{ value: "", isValid: false }],
    disablemodifys3publicaccesssettings: {
      value: data[`${PREFIX}/disablemodifys3publicaccesssettings`] || "yes",
    },
    denydbcreationwithoutencryption: {
      value: data[`${PREFIX}/denydbcreationwithoutencryption`] || "no",
    },
    denyefscreationwithoutencryption: {
      value: data[`${PREFIX}/denyefscreationwithoutencryption`] || "no",
    },
    denyebsvolumecreationwithoutencryption: {
      value: data[`${PREFIX}/denyebsvolumecreationwithoutencryption`] || "no",
    },
    enforcecmkformandatoryencryption: {
      value: data[`${PREFIX}/enforcecmkformandatoryencryption`] || "no",
    },
    mandatorymultifactorauthentication: {
      value: data[`${PREFIX}/mandatorymultifactorauthentication`] || "yes",
    },
    alertonchangetoscp: {
      value: data[`${PREFIX}/alertonchangetoscp`] || "yes",
    }
  };

  return initialFormData;
};

const ServiceControlePolicies = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));
  const [empty, setEmpty] = useState(false);

  const [atleastOneDeny, setAtleastOneDeny] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        setEmpty(result.data.empty);
        setFormData(convertBEToFE(result.data));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if (empty) {
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploybaselinescps`]: formData.deploybaselinescps.value,
    };

    if (formData.deploybaselinescps.value === "yes") {
      be[`${PREFIX}/preventdisablingcoregovernanceservices`] =
        formData.preventdisablingcoregovernanceservices.value;
      be[`${PREFIX}/limitregions`] = formData.limitregions.value;
      be[`${PREFIX}/disallowactionsasrootuser`] =
        formData.disallowactionsasrootuser.value;
      be[`${PREFIX}/disallowcreationofaccesskeysforrootuser`] =
        formData.disallowcreationofaccesskeysforrootuser.value;
      be[`${PREFIX}/preventdeletionofvpcflowlogs`] =
        formData.preventdeletionofvpcflowlogs.value;
      be[`${PREFIX}/preventawsaccountsfromleavingtheorg`] =
        formData.preventawsaccountsfromleavingtheorg.value;
      be[`${PREFIX}/restrictaccessbasedonclientcidrrange`] =
        formData.restrictaccessbasedonclientcidrrange.value;

      if (formData.restrictaccessbasedonclientcidrrange.value === "yes") {
        const cidrblocks = formData.cidrblocks
          .filter((block) => block.isValid)
          .map((block) => block.value)
          .join(",");
        if (cidrblocks !== "") {
          be[`${PREFIX}/restrictaccessbasedonclientcidrrange/cidrblocks`] =
            cidrblocks;
        }
      } else {
        be[`${PREFIX}/restrictaccessbasedonclientcidrrange/cidrblocks`] =
          "none";
      }

      be[`${PREFIX}/disablemodifys3publicaccesssettings`] =
        formData.disablemodifys3publicaccesssettings.value;
      be[`${PREFIX}/denydbcreationwithoutencryption`] =
        formData.denydbcreationwithoutencryption.value;
      be[`${PREFIX}/denyefscreationwithoutencryption`] =
        formData.denyefscreationwithoutencryption.value;
      be[`${PREFIX}/denyebsvolumecreationwithoutencryption`] =
        formData.denyebsvolumecreationwithoutencryption.value;

      if(atleastOneDeny){
        be[`${PREFIX}/enforcecmkformandatoryencryption`] =
        formData.enforcecmkformandatoryencryption.value;
      }  
      else{
        be[`${PREFIX}/enforcecmkformandatoryencryption`] = "no";
      }
      
      be[`${PREFIX}/mandatorymultifactorauthentication`] =
        formData.mandatorymultifactorauthentication.value;

      be[`${PREFIX}/alertonchangetoscp`] =
        formData.alertonchangetoscp.value;  
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const addCidr = () => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, { cidrblocks: { $push: [{ value: "", isValid: false }] } })
    );
  };

  const removeCidr = (idx) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        cidrblocks: { $splice: [[idx, 1]] },
      })
    );
  };

  const onCidrChange = (idx, value) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        cidrblocks: {
          [idx]: {
            value: {
              $set: value,
            },
          },
        },
      })
    );
  };

  const onCidrBlur = (idx, validateFunc, value) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          cidrblocks: {
            [idx]: { isValid: { $set: errorMsg === undefined } },
          },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
          cidrblocks: {
            [idx]: { isValid: { $set: false } },
          },
        })
      );
    }
  };

  useEffect(() => {
    if (
      (formData.denydbcreationwithoutencryption.value === "yes" ||
        formData.denyebsvolumecreationwithoutencryption.value === "yes" ||
        formData.denyefscreationwithoutencryption.value === "yes") &&
      !atleastOneDeny
    ) {
      setAtleastOneDeny(true);
    }

    if (
      formData.denydbcreationwithoutencryption.value === "no" &&
      formData.denyebsvolumecreationwithoutencryption.value === "no" &&
      formData.denyefscreationwithoutencryption.value === "no" &&
      atleastOneDeny
    ) {
      setAtleastOneDeny(false);
      setFormData((state) =>
        update(state, {
          enforcecmkformandatoryencryption: {
            value: {
              $set: "no",
            },
          },
        })
      );
    }
  }, [formData]);

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Governance</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Governance"
                isDirty={isDirty}
                dashboardRoute={ROUTES.GOVERNANCE_SERVICE_CONTROL}
                designRoute={ROUTES.DESIGN_GOVERNANCE}
              ></DomainMenu>
              <fieldset
                disabled={disabled}
                className="mainArea fitDeviceHeight"
              >
                <div class="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <div class="row d-flex align-items-center ">
                    <div class="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                      <h3 class="font-weight-bold">Service Control Policies</h3>
                    </div>
                    <div class="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                      <div class="d-inline-flex yes-no-area align-items-center">
                        <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                          Deploy Service Control Policies
                        </div>
                        <div class="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                          <label
                            className={`createElementBtn btn btn-link btn-linkYes ${
                              formData.deploybaselinescps.value === "yes" &&
                              "active"
                            } mw-70`}
                          >
                            <input
                              type="radio"
                              name="deploybaselinescps"
                              checked={
                                formData.deploybaselinescps.value === "yes"
                              }
                              onChange={() =>
                                updateRadio("deploybaselinescps", "yes")
                              }
                            />{" "}
                            YES
                          </label>
                          <label
                            className={`createElementBtn btn btn-link btn-linkNo ${
                              formData.deploybaselinescps.value === "no" &&
                              "active"
                            } mw-70`}
                          >
                            <input
                              type="radio"
                              name="deploybaselinescps"
                              checked={
                                formData.deploybaselinescps.value === "no"
                              }
                              //onChange={() => //onChange(field, "no") }
                            />{" "}
                            NO
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {empty && <NoInitialData />}
                  {formData.deploybaselinescps.value === "yes" && (
                    <div class="show-hide-content">
                      <div class="row my-1 py-1  mainContentArea">
                        <div class="d-flex col-lg-6 col-12  pl-md-0 px-1 specialHalfColumn">
                          <div class="d-flex flex-column w-100  pl-lg-3 pl-0">
                            <div class=" d-inline-flex formRow  px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div class="one form-line blueSwitch d-inline-flex align-items-center">
                                <label class="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked
                                    disabled
                                    /* checked={
                                    formData
                                      .preventdisablingcoregovernanceservices
                                      .value === "yes"
                                  }
                                  onChange={(e) => {
                                    updateRadio(
                                      "preventdisablingcoregovernanceservices",
                                      e.target.checked ? "yes" : "no"
                                    );
                                  }} */
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <span class="switchLabel lh-md-1">
                                  Prevent users from disabling SecurityHub,
                                  GuardDuty, CloudWatch, AWS Config, CloudTrail
                                  or modifying their configuration
                                </span>
                              </div>
                            </div>

                            <div class=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div class="one form-line d-inline-flex align-items-center">
                                <label class="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData.disallowactionsasrootuser
                                        .value === "yes"
                                    }
                                    onChange={(e) => {
                                      updateRadio(
                                        "disallowactionsasrootuser",
                                        e.target.checked ? "yes" : "no"
                                      );
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <span class="switchLabel lh-md-1">
                                  Disallow actions as a root user
                                </span>
                              </div>
                            </div>

                            <div class=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div class="one form-line d-inline-flex align-items-center">
                                <label class="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData.preventdeletionofvpcflowlogs
                                        .value === "yes"
                                    }
                                    onChange={(e) => {
                                      updateRadio(
                                        "preventdeletionofvpcflowlogs",
                                        e.target.checked ? "yes" : "no"
                                      );
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <span class="switchLabel lh-md-1">
                                  Prevent users from deleting Amazon VPC flow
                                  logs
                                </span>
                              </div>
                            </div>

                            <div class=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div class="one form-line d-inline-flex align-items-center">
                                <label class="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData.disablemodifys3publicaccesssettings
                                        .value === "yes"
                                    }
                                    onChange={(e) => {
                                      updateRadio(
                                        "disablemodifys3publicaccesssettings",
                                        e.target.checked ? "yes" : "no"
                                      );
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <span class="switchLabel lh-md-1">
                                  Disable modification S3 bucket public access
                                  settings
                                </span>
                              </div>
                            </div>

                            <div class=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div class="one form-line d-inline-flex align-items-center">
                                <label class="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData.denydbcreationwithoutencryption
                                        .value === "yes"
                                    }
                                    onChange={(e) => {
                                      updateRadio(
                                        "denydbcreationwithoutencryption",
                                        e.target.checked ? "yes" : "no"
                                      );
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <span class="switchLabel lh-md-1">
                                  Deny creation of databases without encryption
                                </span>
                              </div>
                            </div>

                            <div class=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div class="one form-line d-inline-flex align-items-center">
                                <label class="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData.denyefscreationwithoutencryption
                                        .value === "yes"
                                    }
                                    onChange={(e) => {
                                      updateRadio(
                                        "denyefscreationwithoutencryption",
                                        e.target.checked ? "yes" : "no"
                                      );
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <span class="switchLabel lh-md-1">
                                  Deny creation of filesystem without encryption
                                </span>
                              </div>
                            </div>

                            <div class=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div class="one form-line d-inline-flex align-items-center">
                                <label class="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData
                                        .restrictaccessbasedonclientcidrrange
                                        .value === "yes"
                                    }
                                    onChange={(e) => {
                                      updateRadio(
                                        "restrictaccessbasedonclientcidrrange",
                                        e.target.checked ? "yes" : "no"
                                      );
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <span class="switchLabel lh-md-1">
                                  Restrict console and programmatic user access
                                  based on client CIDR range(s)
                                </span>

                                {formData.restrictaccessbasedonclientcidrrange
                                  .value === "yes" && (
                                  <div className="d-inline-flex flex-wrap">
                                    {formData.cidrblocks.map(
                                      (cidr, cidrIdx) => (
                                        <div
                                          key={cidrIdx}
                                          className="p-relative my-1 mr-1 d-flex align-items-center"
                                        >
                                          <button
                                            disabled={disabled}
                                            type="button"
                                            className="closableInput"
                                            onClick={() => removeCidr(cidrIdx)}
                                          ></button>
                                          <input
                                            type="text"
                                            className={`w-150 form-control`}
                                            placeholder="10.212.0.0/14"
                                            value={cidr.value}
                                            onChange={(e) => {
                                              const value = e.target.value;

                                              if (
                                                formData.cidrblocks.find(
                                                  (cidr) =>
                                                    cidr.isValid &&
                                                    cidr.value !== "" &&
                                                    cidr.value === value
                                                )
                                              ) {
                                                setErrorMsg(
                                                  "Cidr block must be unique"
                                                );
                                                return false;
                                              }

                                              onCidrChange(
                                                cidrIdx,
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) =>
                                              onCidrBlur(
                                                cidrIdx,
                                                validateCidr,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      )
                                    )}
                                    <button
                                      disabled={disabled}
                                      type="button"
                                      className="btn-animation d-flex align-items-center pl-2"
                                      onClick={() => {
                                        addCidr()
                                      }}
                                    >
                                      <img src="../images/purplePlusIcon.svg" />
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          
                            <div class=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div class="one form-line d-inline-flex align-items-center">
                                <label class="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData.alertonchangetoscp
                                        .value === "yes"
                                    }
                                    onChange={(e) => {
                                      updateRadio(
                                        "alertonchangetoscp",
                                        e.target.checked ? "yes" : "no"
                                      );
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <span class="switchLabel lh-md-1">
                                  Alert on SCP changes
                                </span>
                              </div>
                            </div>          
                          </div>
                        </div>

                        <div class="d-flex col-lg-6 col-12  pr-md-0 px-2 specialHalfColumn">
                          <div class="d-flex flex-column w-100 pr-lg-3 pr-0 ">
                            <div class=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div class="one form-line  d-inline-flex align-items-center">
                                <label class="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData.limitregions.value === "yes"
                                    }
                                    onChange={(e) => {
                                      updateRadio(
                                        "limitregions",
                                        e.target.checked ? "yes" : "no"
                                      );
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <span class="switchLabel lh-md-1">
                                  Prevent access to AWS regions other than the
                                  allowed ones
                                </span>
                              </div>
                            </div>

                            <div class=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div class="one form-line  d-inline-flex align-items-center">
                                <label class="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData
                                        .disallowcreationofaccesskeysforrootuser
                                        .value === "yes"
                                    }
                                    onChange={(e) => {
                                      updateRadio(
                                        "disallowcreationofaccesskeysforrootuser",
                                        e.target.checked ? "yes" : "no"
                                      );
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <span class="switchLabel lh-md-1">
                                  Disallow creation of access keys for the root
                                  user
                                </span>
                              </div>
                            </div>

                            <div class=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div class="one form-line  d-inline-flex align-items-center">
                                <label class="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData
                                        .preventawsaccountsfromleavingtheorg
                                        .value === "yes"
                                    }
                                    onChange={(e) => {
                                      updateRadio(
                                        "preventawsaccountsfromleavingtheorg",
                                        e.target.checked ? "yes" : "no"
                                      );
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <span class="switchLabel lh-md-1">
                                  Prevent AWS accounts from leaving the
                                  organization
                                </span>
                              </div>
                            </div>

                            <div class=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div class="one form-line d-inline-flex align-items-center">
                                <label class="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData.denyebsvolumecreationwithoutencryption
                                        .value === "yes"
                                    }
                                    onChange={(e) => {
                                      updateRadio(
                                        "denyebsvolumecreationwithoutencryption",
                                        e.target.checked ? "yes" : "no"
                                      );
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <span class="switchLabel lh-md-1">
                                  Deny creation of EBS volumes without
                                  encryption
                                </span>
                              </div>
                            </div>

                            <div class=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div class={`one form-line d-inline-flex align-items-center `}>
                                <label class="switch mb-0">
                                  <input
                                    disabled={!atleastOneDeny}
                                    type="checkbox"
                                    checked={
                                      formData.enforcecmkformandatoryencryption.value === "yes"
                                    }
                                    onChange={(e) => {
                                      updateRadio(
                                        "enforcecmkformandatoryencryption",
                                        e.target.checked ? "yes" : "no"
                                      );
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <span class="switchLabel lh-md-1">
                                  Verify and enforce mandatory encryption is
                                  done with customer managed keys
                                </span>
                              </div>
                            </div>

                            <div class=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                              <div class="one form-line d-inline-flex align-items-center">
                                <label class="switch mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      formData.mandatorymultifactorauthentication.value === "yes"
                                    }
                                    onChange={(e) => {
                                      updateRadio(
                                        "mandatorymultifactorauthentication",
                                        e.target.checked ? "yes" : "no"
                                      );
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <span class="switchLabel lh-md-1">
                                  Mandatory multi-factor authentication (MFA)
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.GOVERNANCE_TAGGING_STRATEGY + "/" + projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Tagging Strategy
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.GOVERNANCE_CONFIG_RULES + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Config Rules
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceControlePolicies;

