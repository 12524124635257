import React from "react";
import * as ROUTES from "../../../constants/routes";

const AlicloudPrelaunchComponent = ({ projectId, prelaunchData, setErrorMsg }) => {
  const getClassName = (key) => {
    if (prelaunchData) {
      let className;

      switch (prelaunchData[key].status) {
        case "NA":
          className = "naInput";
          break;
        case "FAIL":
          className = "cancelInput";
          break;
        case "PASS":
          className = "checkmarkInput";
          break;
        case "NODATA":
          className = "noDataInput";
          break;
        default:
          className = "signsInput";
          break;
      }

      return className;
    } else {
      return "signsInput";
    }
  };

  const onClick = (key) => {
    if (prelaunchData[key].status === "FAIL") {
      setErrorMsg(prelaunchData[key].message);
    }
  };

  const getGrayText = (key) => {
    return prelaunchData && prelaunchData[key].status === "NA"
      ? "text-color-gray"
      : "";
  };

  return (
    <>
      <div className="mainContentArea preLaunchArea row px-2 py-2 mt-2">
        <div className="col-xl-6 col-lg-6 col-12 p-0">
          <div className="row">
            <div className="col-md-6 col-12 d-flex flex-column">
              <h3 className="font-15">Account Framework</h3>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("enterpriseCoreAccounts")}
                  onClick={() => onClick("enterpriseCoreAccounts")}
                ></span>
                <a
                  href={`${ROUTES.ALICLOUD_ENTERPRISE_CORE_ACCOUNTS}/${projectId}`}
                  className="font-12"
                >
                  Enterprise Core Accounts
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("environmentStrategy")}
                  onClick={() => onClick("environmentStrategy")}
                ></span>
                <a
                  href={`${ROUTES.ALICLOUD_ENVIRONMENT_STRATEGY}/${projectId}`}
                  className="font-12"
                >
                  Envionment Strategy
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("applicationAccounts")}
                  onClick={() => onClick("applicationAccounts")}
                ></span>
                <a
                  href={`${ROUTES.ALICLOUD_APPLICATION_ACCOUNTS}/${projectId}`}
                  className="font-12"
                >
                  Application Accounts
                </a>
              </div>
              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                <span
                  className={getClassName("developerSandboxAccounts")}
                  onClick={() => onClick("developerSandboxAccounts")}
                ></span>
                <a
                  href={`${ROUTES.ALICLOUD_DEVELOPER_SANDBOX_ACCOUNTS}/${projectId}`}
                  className="font-12"
                >
                  Developer Sandbox Accounts
                </a>
              </div>
            </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default AlicloudPrelaunchComponent;
