import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import * as ROUTES from "../constants/routes";

import 'react-toastify/dist/ReactToastify.css';

import EnvironmentStrategy from "./project/aws/account-framework/EnvironmentStrategy";
import NotFound from "./NotFound";
import EnterpriseCoreAccount3 from "./project/aws/account-framework/EnterpriseCoreAccount3";
import ApplicationAccounts from "./project/aws/account-framework/ApplicationAccounts";
import DeveloperSandboxAccounts from "./project/aws/account-framework/DeveloperSandboxAccounts";
import OrganizationalUnits from "./project/aws/account-framework/OrganizationalUnits";
import Accounts from "./project/aws/account-framework/Accounts";
/* import OktaIntegration from "./project/aws/iam/OktaIntegration";
import AdfsIntegration from "./project/aws/iam/AdfsIntegration";
import AwsSsoIntegration from "./project/aws/iam/AwsSsoIntegration"; */
import BaselineRoles from "./project/aws/iam/BaselineRoles";
import SingleSignOn from "./project/aws/iam/SingleSignOn";
import PrivilegedAccessManagement from "./project/aws/iam/PrivilegedAccessManagement";
import DataCenters from "./project/aws/network/DataCenters";
import AwsEnvironment from "./project/aws/network/AwsEnvironment";
import VPCDesign from "./project/aws/network/VPCDesign";
import VPCFactory from "./project/aws/network/VPCFactory";
import InterVPCConnectivity from "./project/aws/network/InterVPCConnectivity";
import CrossRegionConnectivity from "./project/aws/network/CrossRegionConnectivity";
import VPNConnectivity from "./project/aws/network/VPNConnectivity";
import DirectConnect from "./project/aws/network/DirectConnect";
import Prelaunch from "./project/aws/Prelaunch";
import IdentityProviders from "./project/aws/iam/IdentityProviders";
import IamRoles from "./project/aws/iam/IamRoles";
import VPC from "./project/aws/network/Dashboard/VPC";
import VPCDhcp from "./project/aws/network/Dashboard/VPCDhcp";
import VPCEndpoints from "./project/aws/network/Dashboard/VPCEndpoints";
import TrafficMirrorTargets from "./project/aws/network/Dashboard/TrafficMirrorTargets";
import VPCRouteTables from "./project/aws/network/Dashboard/VPCRouteTables";
import TGW from "./project/aws/network/Dashboard/TGW";
import TGWRoutingDomains from "./project/aws/network/Dashboard/TGWRoutingDomains";
import TGWAttachments from "./project/aws/network/Dashboard/TGWAttachments";
import VPN from "./project/aws/network/Dashboard/VPN";
import VPNDirectConnect from "./project/aws/network/Dashboard/VPNDirectConnect";
import VPNDirectConnectGateway from "./project/aws/network/Dashboard/VPNDirectConnectGateway";
import ResourceSharesbyme from "./project/aws/network/Dashboard/ResourceSharesbyme";
import ResourceShareswithme from "./project/aws/network/Dashboard/ResourceShareswithme";
import AdminClients from "./admin/Clients";
//import Home from "./Home";
import Contact from "./Contact";
import Careers from "./Careers";
import NewHome from "./NewHome";
import AdminProjects from "./admin/Projects";
import AdminUsers from "./admin/Users";
import DesignAccountFramework from "./project/aws/account-framework/DesignAccountFramework";
import DesignIam from "./project/aws/iam/DesignIam";
import DesignNetwork from "./project/aws/network/DesignNetwork";
import ProtectedComponent from "./ProtectedComponent";
import { ToastContainer } from "react-toastify";
import VPCSubnets from "./project/aws/network/Dashboard/VPCSubnets";
import VPCSharedEndpoints from "./project/aws/network/VPCSharedEndpoints";
import NetworkFirewalls from "./project/aws/network/Dashboard/NetworkFirewalls";
import NetworkFirewallPolicies from "./project/aws/network/Dashboard/NetworkFirewallPolicies";
import NetworkFirewallRuleGroups from "./project/aws/network/Dashboard/NetworkFirewallRuleGroups";


import ACMCertificates from "./project/aws/data-protection/Dashboard/ACMCertificates";
import BackupPlans from "./project/aws/data-protection/Dashboard/BackupPlans";
import BackupPolicies from "./project/aws/data-protection/Dashboard/BackupPolicies";
import BackupVaults from "./project/aws/data-protection/Dashboard/BackupVaults";
import BackupJobs from "./project/aws/data-protection/Dashboard/BackupJobs";
import CopyJobs from "./project/aws/data-protection/Dashboard/CopyJobs";
import Keys from "./project/aws/data-protection/Dashboard/Keys";
import KeyStores from "./project/aws/data-protection/Dashboard/KeyStores";
import KeyManagement from "./project/aws/data-protection/KeyManagement";
import DesignDataProtection from "./project/aws/data-protection/DesignDataProtection";
import CertificateManagement from "./project/aws/data-protection/CertificateManagement";
import BackupArchival from "./project/aws/data-protection/BackupArchival";

import Logs from "./admin/Logs";
import TrafficInspection from "./project/aws/network/TrafficInspection";
import ComplianceManagement from "./project/aws/security/ComplianceManagement";
import EndpointProtection from "./project/aws/security/EndpointProtection";
import IntelligentThreatDetection from "./project/aws/security/IntelligentThreatDetection";
import PerimeterProtection from "./project/aws/security/PerimeterProtection";
import SecretsManagement from "./project/aws/security/SecretsManagement";
import SIEM from "./project/aws/security/SIEM";
import VulnerabilityManagement from "./project/aws/security/VulnerabilityManagement";
import ADIntegration from "./project/aws/infrastructure/ADIntegration";
import ImageManagement from "./project/aws/infrastructure/ImageManagement";
import PatchManagement from "./project/aws/infrastructure/PatchManagement";
import PrivateDNS from "./project/aws/infrastructure/PrivateDNS";
import VDISolution from "./project/aws/infrastructure/VDISolution";
import AWSLimitsMonitor from "./project/aws/governance/AWSLimitsMonitor";
import BudgetEnforcement from "./project/aws/governance/BudgetEnforcement";
import ConfigRules from "./project/aws/governance/ConfigRules";
import ServiceNowIntegration from "./project/aws/governance/ServiceNowIntegration";
import ServiceControlePolicies from "./project/aws/governance/ServiceControlPolicies";
import TaggingStrategy from "./project/aws/governance/TaggingStrategy";

import SecurityHubMembers from "./project/aws/security/Dashboard/SecurityHubMembers";
import SecurityHubStandards from "./project/aws/security/Dashboard/SecurityHubStandards";
import GuardDutyMembers from "./project/aws/security/Dashboard/GuardDuty";
import SecretsManager from "./project/aws/security/Dashboard/SecretsManager"; 
import SecurityHubIntegrations from "./project/aws/security/Dashboard/SecurityHubIntegrations";
import FMSSecurityPolicies from "./project/aws/security/Dashboard/FMSSecurityPolicies";
import FMSAppsList from "./project/aws/security/Dashboard/FMSAppsList";
import FMSProtocolsList from "./project/aws/security/Dashboard/FMSProtocolsList";
import Directories from "./project/aws/infrastructure/Dashboard/Directories";
import SharedWithMe from "./project/aws/infrastructure/Dashboard/SharedWithMe";
import InboundEndpoints from "./project/aws/infrastructure/Dashboard/InboundEndpoints";
import OutboundEndpoints from "./project/aws/infrastructure/Dashboard/OutboundEndpoints";
import ResolverRules from "./project/aws/infrastructure/Dashboard/ResolverRules";
import ImageComponents from "./project/aws/infrastructure/Dashboard/Components";
import Recipes from "./project/aws/infrastructure/Dashboard/Recipes";
import Pipelines from "./project/aws/infrastructure/Dashboard/Pipelines";
import Images from "./project/aws/infrastructure/Dashboard/Images";
import Baselines from "./project/aws/infrastructure/Dashboard/Baselines";
import Groups from "./project/aws/infrastructure/Dashboard/Groups";
import MaintenanceWindows from "./project/aws/infrastructure/Dashboard/MaintenanceWindows";
import Workspaces from "./project/aws/infrastructure/Dashboard/Workspaces";
import IPAccessControls from "./project/aws/infrastructure/Dashboard/IPAccessControls";
import ServiceControl from "./project/aws/governance/Dashboard/ServiceControl";
import Tagging from "./project/aws/governance/Dashboard/Tagging";
import Recorders from "./project/aws/governance/Dashboard/Recorders";
import Rules from "./project/aws/governance/Dashboard/Rules";
import AWSBudgets from "./project/aws/governance/Dashboard/AWSBudgets";
import Portfolios from "./project/aws/governance/Dashboard/Portfolios";
import Products from "./project/aws/governance/Dashboard/Products";
import DesignSecurity from "./project/aws/security/DesignSecurity";
import DesignInfrastructure from "./project/aws/infrastructure/DesignInfrastructure";
import DesignGovernance from "./project/aws/governance/DesignGovernance";
import CloudwatchEvents from "./project/aws/monitoring/CloudwatchEvents";
import CentralizedLogging from "./project/aws/monitoring/CentralizedLogging";
import LogAnalytics from "./project/aws/monitoring/LogAnalytics";
import MonitoringToolsIntegration from "./project/aws/monitoring/MonitoringToolsIntegration";
import SerivcenowImIntegration from "./project/aws/monitoring/ServicenowImIntegration";
import DesignMonitoring from "./project/aws/monitoring/DesignMonitoring";
import FinOps from "./project/aws/governance/FinOps";
import DataPrivacy from "./project/aws/data-protection/DataPrivacy";
import DataLossPrevention from "./project/aws/data-protection/DataLossPrevention";

import DigitalDeveloperPortal from "./project/aws/digital/DeveloperPortal";
import DigitalDeveloperTools from "./project/aws/digital/DeveloperTools";
import DigitalStarterKits from "./project/aws/digital/StarterKits";


import GcpEnvironmentStrategy from "./project/gcp/account-framework/EnvironmentStrategy";
import GcpEnterpriseCoreAccount3 from "./project/gcp/account-framework/EnterpriseCoreAccount3";
import GcpApplicationAccounts from "./project/gcp/account-framework/ApplicationAccounts";
import GcpDeveloperSandboxAccounts from "./project/gcp/account-framework/DeveloperSandboxAccounts";
import GcpOrganizationalUnits from "./project/gcp/account-framework/OrganizationalUnits";
import GcpAccounts from "./project/gcp/account-framework/Accounts";
import GcpDesignAccountFramework from "./project/gcp/account-framework/DesignAccountFramework";

import GcpPrelaunch from "./project/gcp/Prelaunch";

import AlicloudEnvironmentStrategy from "./project/alicloud/account-framework/EnvironmentStrategy";
import AlicloudEnterpriseCoreAccount3 from "./project/alicloud/account-framework/EnterpriseCoreAccount3";
import AlicloudApplicationAccounts from "./project/alicloud/account-framework/ApplicationAccounts";
import AlicloudDeveloperSandboxAccounts from "./project/alicloud/account-framework/DeveloperSandboxAccounts";
import AlicloudOrganizationalUnits from "./project/alicloud/account-framework/OrganizationalUnits";
import AlicloudAccounts from "./project/alicloud/account-framework/Accounts";
import AlicloudDesignAccountFramework from "./project/alicloud/account-framework/DesignAccountFramework";

import AlicloudPrelaunch from "./project/alicloud/Prelaunch";

import AzureEnvironmentStrategy from "./project/azure/account-framework/EnvironmentStrategy";
import AzureEnterpriseCoreAccount3 from "./project/azure/account-framework/EnterpriseCoreAccount3";
import AzureApplicationAccounts from "./project/azure/account-framework/ApplicationAccounts";
import AzureDeveloperSandboxAccounts from "./project/azure/account-framework/DeveloperSandboxAccounts";
import AzureOrganizationalUnits from "./project/azure/account-framework/OrganizationalUnits";
import AzureAccounts from "./project/azure/account-framework/Accounts";
import AzureDesignAccountFramework from "./project/azure/account-framework/DesignAccountFramework";

import AzurePrelaunch from "./project/azure/Prelaunch";
import Configure from "./project/aws/Configure";
import AzureConfigure from "./project/azure/Configure";
import GcpConfigure from "./project/gcp/Configure";
import AlicloudConfigure from "./project/alicloud/Configure";


const App = () => {
  return (
    <>
      <ToastContainer></ToastContainer>
      <Router>
        <Switch>
          <Route path={ROUTES.PRELAUNCH + "/:projectId"}>
            <ProtectedComponent Component={Prelaunch}></ProtectedComponent>
          </Route>

          <Route path={ROUTES.CONFIGURE + "/:projectId"}>
            <ProtectedComponent Component={Configure}></ProtectedComponent>
          </Route>

          <Route path={ROUTES.ENTERPRISE_CORE_ACCOUNTS + "/:projectId"}>
            <ProtectedComponent
              Component={EnterpriseCoreAccount3}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.ENVIRONMENT_STRATEGY + "/:projectId"}>
            <ProtectedComponent
              Component={EnvironmentStrategy}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.APPLICATION_ACCOUNTS + "/:projectId"}>
            <ProtectedComponent
              Component={ApplicationAccounts}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.DEVELOPER_SANDBOX_ACCOUNTS + "/:projectId"}>
            <ProtectedComponent
              Component={DeveloperSandboxAccounts}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.ORGANIZATIONAL_UNITS + "/:projectId"}>
            <ProtectedComponent
              Component={OrganizationalUnits}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.ACCOUNTS + "/:projectId"}>
            <ProtectedComponent Component={Accounts}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.DESIGN_ACCOUNT_FRAMEWORK + "/:projectId"}>
            <ProtectedComponent
              Component={DesignAccountFramework}
            ></ProtectedComponent>
          </Route>

        {/*   <Route path={ROUTES.OKTA_INTEGRATION + "/:projectId"}>
            <ProtectedComponent
              Component={OktaIntegration}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.ADFS_INTEGRATION + "/:projectId"}>
            <ProtectedComponent
              Component={AdfsIntegration}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.AWS_SSO_INTEGRATION + "/:projectId"}>
            <ProtectedComponent
              Component={AwsSsoIntegration}
            ></ProtectedComponent>
          </Route> */}
          <Route path={ROUTES.BASELINE_ROLES + "/:projectId"}>
            <ProtectedComponent
              Component={BaselineRoles}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.SINGLE_SIGN_ON + "/:projectId"}>
            <ProtectedComponent
              Component={SingleSignOn}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.PRIVILEGED_ACCESS_MANAGEMENT + "/:projectId"}>
            <ProtectedComponent
              Component={PrivilegedAccessManagement}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.IDENTITY_PROVIDERS + "/:projectId"}>
            <ProtectedComponent
              Component={IdentityProviders}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.IAM_ROLES + "/:projectId"}>
            <ProtectedComponent Component={IamRoles}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.DESIGN_IAM + "/:projectId"}>
            <ProtectedComponent Component={DesignIam}></ProtectedComponent>
          </Route>

          <Route path={ROUTES.DATA_CENTERS + "/:projectId"}>
            <ProtectedComponent Component={DataCenters}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.AWS_ENVIRONMENT + "/:projectId"}>
            <ProtectedComponent Component={AwsEnvironment}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.VPC_DESIGN + "/:projectId"}>
            <ProtectedComponent Component={VPCDesign}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.VPC_FACTORY + "/:projectId"}>
            <ProtectedComponent Component={VPCFactory}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INTER_VPC_CONNECTIVITY + "/:projectId"}>
            <ProtectedComponent
              Component={InterVPCConnectivity}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.CROSS_REGION_CONNECTIVITY + "/:projectId"}>
            <ProtectedComponent
              Component={CrossRegionConnectivity}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.VPC_CONNECTIVITY + "/:projectId"}>
            <ProtectedComponent
              Component={VPNConnectivity}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.DIRECT_CONNECT_CONNECTIVITY + "/:projectId"}>
            <ProtectedComponent Component={DirectConnect}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.TRAFFIC_INSPECTION + "/:projectId"}>
            <ProtectedComponent Component={TrafficInspection}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.VPC + "/:projectId"}>
            <ProtectedComponent Component={VPC}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.VPC_SUBNETS + "/:projectId"}>
            <ProtectedComponent Component={VPCSubnets}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.VPC_DHCP + "/:projectId"}>
            <ProtectedComponent Component={VPCDhcp}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.VPC_END_POINTS + "/:projectId"}>
            <ProtectedComponent Component={VPCEndpoints}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.VPC_ROUTE_TABLES + "/:projectId"}>
            <ProtectedComponent Component={VPCRouteTables}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.TGW + "/:projectId"}>
            <ProtectedComponent Component={TGW}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.TGW_ROUTING_DOMAINS + "/:projectId"}>
            <ProtectedComponent
              Component={TGWRoutingDomains}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.TGW_ATTACHMENT + "/:projectId"}>
            <ProtectedComponent Component={TGWAttachments}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.VPN + "/:projectId"}>
            <ProtectedComponent Component={VPN}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.VPN_DIRECT_CONNECT + "/:projectId"}>
            <ProtectedComponent
              Component={VPNDirectConnect}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.VPN_DIRECT_CONNECT_GATEWAY + "/:projectId"}>
            <ProtectedComponent
              Component={VPNDirectConnectGateway}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.RESOURCE_SHARES_BY_ME + "/:projectId"}>
            <ProtectedComponent
              Component={ResourceSharesbyme}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.RESOURCE_SHARES_WITH_ME + "/:projectId"}>
            <ProtectedComponent
              Component={ResourceShareswithme}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.TRAFFIC_MIRROR_TARGETS + "/:projectId"}>
            <ProtectedComponent
              Component={TrafficMirrorTargets}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.DESIGN_NETWORK + "/:projectId"}>
            <ProtectedComponent Component={DesignNetwork}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.VPC_SHAREDENDPOINTS + "/:projectId"}>
            <ProtectedComponent Component={VPCSharedEndpoints}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.NETWORK_FIREWALL_FIREWALLS + "/:projectId"}>
            <ProtectedComponent Component={NetworkFirewalls}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.NETWORK_FIREWALL_POLICIES + "/:projectId"}>
            <ProtectedComponent Component={NetworkFirewallPolicies}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.NETWORK_FIREWALL_RULEGROUPS + "/:projectId"}>
            <ProtectedComponent Component={NetworkFirewallRuleGroups}></ProtectedComponent>
          </Route>

          <Route path={ROUTES.DATA_PROTECTION_ACM + "/:projectId"}>
            <ProtectedComponent Component={ACMCertificates}></ProtectedComponent>
          </Route>

          <Route path={ROUTES.DATA_PROTECTION_BACKUP_POLICY + "/:projectId"}>
            <ProtectedComponent Component={BackupPolicies}></ProtectedComponent>
          </Route>

          <Route path={ROUTES.DATA_PROTECTION_BACKUP_PLAN + "/:projectId"}>
            <ProtectedComponent Component={BackupPlans}></ProtectedComponent>
          </Route>

          <Route path={ROUTES.DATA_PROTECTION_BACKUP_VAULTS + "/:projectId"}>
            <ProtectedComponent Component={BackupVaults}></ProtectedComponent>
          </Route>

          <Route path={ROUTES.DATA_PROTECTION_BACKUP_JOBS + "/:projectId"}>
            <ProtectedComponent Component={BackupJobs}></ProtectedComponent>
          </Route>

          <Route path={ROUTES.DATA_PROTECTION_BACKUP_COPY_JOBS + "/:projectId"}>
            <ProtectedComponent Component={CopyJobs}></ProtectedComponent>
          </Route>

          <Route path={ROUTES.DATA_PROTECTION_KEYS + "/:projectId"}>
            <ProtectedComponent Component={Keys}></ProtectedComponent>
          </Route>

          <Route path={ROUTES.DATA_PROTECTION_KEY_STORES + "/:projectId"}>
            <ProtectedComponent Component={KeyStores}></ProtectedComponent>
          </Route>

          <Route path={ROUTES.DATA_PROTECTION_KEY_MANAGEMENT + "/:projectId"}>
            <ProtectedComponent Component={KeyManagement} ></ProtectedComponent>
          </Route>

          <Route path={ROUTES.DATA_PROTECTION_CERTIFICATE_MANAGEMENT + "/:projectId"}>
            <ProtectedComponent Component={CertificateManagement} ></ProtectedComponent>
          </Route>

          <Route path={ROUTES.DATA_PROTECTION_BACKUP_ARCHIVAL + "/:projectId"}>
            <ProtectedComponent Component={BackupArchival} ></ProtectedComponent>
          </Route>

          <Route path={ROUTES.DESIGN_DATA_PROTECTION + "/:projectId"}>
            <ProtectedComponent Component={DesignDataProtection} ></ProtectedComponent>
          </Route>


          <Route path={ROUTES.SECURITY_COMPLIANCE_MANAGEMENT + "/:projectId"}>
            <ProtectedComponent Component={ComplianceManagement} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.SECURITY_ENDPOINT_PROTECTION + "/:projectId"}>
            <ProtectedComponent Component={EndpointProtection} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.SECURITY_INTELLIGENT_THREAT_DETECTION + "/:projectId"}>
            <ProtectedComponent Component={IntelligentThreatDetection} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.SECURITY_PERIMETER_PROTECTION + "/:projectId"}>
            <ProtectedComponent Component={PerimeterProtection} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.SECURITY_SECRETS_MANAGEMENT + "/:projectId"}>
            <ProtectedComponent Component={SecretsManagement} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.SECURITY_SIEM + "/:projectId"}>
            <ProtectedComponent Component={SIEM} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.SECURITY_VULNERABILITY_MANAGEMENT + "/:projectId"}>
            <ProtectedComponent Component={VulnerabilityManagement} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.DESIGN_SECURITY + "/:projectId"}>
            <ProtectedComponent Component={DesignSecurity}></ProtectedComponent>
          </Route>

          <Route path={ROUTES.SECURITY_HUB_MEMBERS + "/:projectId"}>
            <ProtectedComponent Component={SecurityHubMembers} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.SECURITY_HUB_STANDARDS + "/:projectId"}>
            <ProtectedComponent Component={SecurityHubStandards} ></ProtectedComponent>
          </Route>
         <Route path={ROUTES.SECURITY_HUB_INTEGRATIONS + "/:projectId"}>
            <ProtectedComponent Component={SecurityHubIntegrations} ></ProtectedComponent>
          </Route> 
          <Route path={ROUTES.SECURITY_GUARDDUTY_MEMBERS + "/:projectId"}>
            <ProtectedComponent Component={GuardDutyMembers} ></ProtectedComponent>
          </Route> 
          <Route path={ROUTES.SECURITY_SECRETS_MANAGER + "/:projectId"}>
            <ProtectedComponent Component={SecretsManager} ></ProtectedComponent>
          </Route> 
          <Route path={ROUTES.SECURITY_FIREWALL_SECURITY_POLICIES + "/:projectId"}>
            <ProtectedComponent Component={FMSSecurityPolicies} ></ProtectedComponent>
          </Route> 
          <Route path={ROUTES.SECURITY_FIREWALL_APP_LISTS + "/:projectId"}>
            <ProtectedComponent Component={FMSAppsList} ></ProtectedComponent>
          </Route> 
          <Route path={ROUTES.SECURITY_FIREWALL_PROTOCOL_LISTS + "/:projectId"}>
            <ProtectedComponent Component={FMSProtocolsList} ></ProtectedComponent>
          </Route> 


          <Route path={ROUTES.INFRASTRUCTURE_ADIntegration + "/:projectId"}>
            <ProtectedComponent Component={ADIntegration} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_IMAGEMANAGEMENT + "/:projectId"}>
            <ProtectedComponent Component={ImageManagement} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_PATCHMANAGEMENT + "/:projectId"}>
            <ProtectedComponent Component={PatchManagement} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_PRIVATEDNS + "/:projectId"}>
            <ProtectedComponent Component={PrivateDNS} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_VDISOLUTION + "/:projectId"}>
            <ProtectedComponent Component={VDISolution} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.DESIGN_INFRASTRUCTURE + "/:projectId"}>
            <ProtectedComponent Component={DesignInfrastructure}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_DIRECTORIES + "/:projectId"}>
            <ProtectedComponent Component={Directories} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_SHARED_WITH_ME + "/:projectId"}>
            <ProtectedComponent Component={SharedWithMe} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_INBOUND_ENDPOINTS + "/:projectId"}>
            <ProtectedComponent Component={InboundEndpoints} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_OUTBOUND_ENDPOINTS + "/:projectId"}>
            <ProtectedComponent Component={OutboundEndpoints} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_RESOLVER_RULES + "/:projectId"}>
            <ProtectedComponent Component={ResolverRules} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_COMPONENTS + "/:projectId"}>
            <ProtectedComponent Component={ImageComponents} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_RECIPES + "/:projectId"}>
            <ProtectedComponent Component={Recipes} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_PIPELINES + "/:projectId"}>
            <ProtectedComponent Component={Pipelines} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_IMAGES + "/:projectId"}>
            <ProtectedComponent Component={Images} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_BASELINES + "/:projectId"}>
            <ProtectedComponent Component={Baselines} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_GROUPS + "/:projectId"}>
            <ProtectedComponent Component={Groups} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_MAINTENANCE_WINDOWS + "/:projectId"}>
            <ProtectedComponent Component={MaintenanceWindows} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_WORKSPACES + "/:projectId"}>
            <ProtectedComponent Component={Workspaces} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.INFRASTRUCTURE_IP_ACCESS_CONTROLS + "/:projectId"}>
            <ProtectedComponent Component={IPAccessControls} ></ProtectedComponent>
          </Route>


          <Route path={ROUTES.GOVERNANCE_AWS_LIMITS_MONITOR + "/:projectId"}>
            <ProtectedComponent Component={AWSLimitsMonitor} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GOVERNANCE_BUDGET_ENFORCEMENT + "/:projectId"}>
            <ProtectedComponent Component={BudgetEnforcement} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GOVERNANCE_CONFIG_RULES + "/:projectId"}>
            <ProtectedComponent Component={ConfigRules} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GOVERNANCE_SERVICENOW_INTEGRATION + "/:projectId"}>
            <ProtectedComponent Component={ServiceNowIntegration} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GOVERNANCE_SERVICE_CONTROL_POLICIES + "/:projectId"}>
            <ProtectedComponent Component={ServiceControlePolicies} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GOVERNANCE_TAGGING_STRATEGY + "/:projectId"}>
            <ProtectedComponent Component={TaggingStrategy} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GOVERNANCE_SERVICE_CONTROL + "/:projectId"}>
            <ProtectedComponent Component={ServiceControl} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.DESIGN_GOVERNANCE + "/:projectId"}>
            <ProtectedComponent Component={DesignGovernance}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GOVERNANCE_TAGGING + "/:projectId"}>
            <ProtectedComponent Component={Tagging} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GOVERNANCE_RECORDERS + "/:projectId"}>
            <ProtectedComponent Component={Recorders} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GOVERNANCE_RULES + "/:projectId"}>
            <ProtectedComponent Component={Rules} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GOVERNANCE_AWS_BUDGETS + "/:projectId"}>
            <ProtectedComponent Component={AWSBudgets} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GOVERNANCE_PORTFOLIOS + "/:projectId"}>
            <ProtectedComponent Component={Portfolios} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GOVERNANCE_PRODUCTS + "/:projectId"}>
            <ProtectedComponent Component={Products} ></ProtectedComponent>
          </Route>
         {/*  <Route path={ROUTES.GOVERNANCE_FIN_OPS + "/:projectId"}>
            <ProtectedComponent Component={FinOps} ></ProtectedComponent>
          </Route> */}

          <Route path={ROUTES.MONITORING_CLOUDWATCH_EVENTS + "/:projectId"}>
            <ProtectedComponent Component={CloudwatchEvents} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.MONITORING_CENTRALIZED_LOGGING + "/:projectId"}>
            <ProtectedComponent Component={CentralizedLogging} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.MONITORING_LOG_ANALYTICS + "/:projectId"}>
            <ProtectedComponent Component={LogAnalytics} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.MONITORING_MONITORING_TOOLS_INTEGRATION + "/:projectId"}>
            <ProtectedComponent Component={MonitoringToolsIntegration} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.MONITORING_SERVICENOW_IM_INTEGRATION + "/:projectId"}>
            <ProtectedComponent Component={SerivcenowImIntegration} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.DESIGN_MONITORING + "/:projectId"}>
            <ProtectedComponent Component={DesignMonitoring} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.DIGITAL_DEV_PORTAL + "/:projectId"}>
            <ProtectedComponent Component={DigitalDeveloperPortal} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.DIGITAL_DEV_TOOLS + "/:projectId"}>
            <ProtectedComponent Component={DigitalDeveloperTools} ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.DIGITAL_STARTER_KITS + "/:projectId"}>
            <ProtectedComponent Component={DigitalStarterKits} ></ProtectedComponent>
          </Route>

          <Route path={ROUTES.GCP_ENTERPRISE_CORE_ACCOUNTS + "/:projectId"}>
            <ProtectedComponent
              Component={GcpEnterpriseCoreAccount3}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GCP_ENVIRONMENT_STRATEGY + "/:projectId"}>
            <ProtectedComponent
              Component={GcpEnvironmentStrategy}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GCP_APPLICATION_ACCOUNTS + "/:projectId"}>
            <ProtectedComponent
              Component={GcpApplicationAccounts}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GCP_DEVELOPER_SANDBOX_ACCOUNTS + "/:projectId"}>
            <ProtectedComponent
              Component={GcpDeveloperSandboxAccounts}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GCP_ORGANIZATIONAL_UNITS + "/:projectId"}>
            <ProtectedComponent
              Component={GcpOrganizationalUnits}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GCP_ACCOUNTS + "/:projectId"}>
            <ProtectedComponent Component={GcpAccounts}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.GCP_DESIGN_ACCOUNT_FRAMEWORK + "/:projectId"}>
            <ProtectedComponent
              Component={GcpDesignAccountFramework}
            ></ProtectedComponent>
          </Route>

          <Route path={ROUTES.GCP_PRELAUNCH + "/:projectId"}>
            <ProtectedComponent
              Component={GcpPrelaunch}
            ></ProtectedComponent>
          </Route>

          <Route path={ROUTES.GCP_CONFIGURE + "/:projectId"}>
            <ProtectedComponent
              Component={GcpConfigure}
            ></ProtectedComponent>
          </Route>

          <Route path={ROUTES.ALICLOUD_ENTERPRISE_CORE_ACCOUNTS + "/:projectId"}>
            <ProtectedComponent
              Component={AlicloudEnterpriseCoreAccount3}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.ALICLOUD_ENVIRONMENT_STRATEGY + "/:projectId"}>
            <ProtectedComponent
              Component={AlicloudEnvironmentStrategy}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.ALICLOUD_APPLICATION_ACCOUNTS + "/:projectId"}>
            <ProtectedComponent
              Component={AlicloudApplicationAccounts}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.ALICLOUD_DEVELOPER_SANDBOX_ACCOUNTS + "/:projectId"}>
            <ProtectedComponent
              Component={AlicloudDeveloperSandboxAccounts}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.ALICLOUD_ORGANIZATIONAL_UNITS + "/:projectId"}>
            <ProtectedComponent
              Component={AlicloudOrganizationalUnits}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.ALICLOUD_ACCOUNTS + "/:projectId"}>
            <ProtectedComponent Component={AlicloudAccounts}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.ALICLOUD_DESIGN_ACCOUNT_FRAMEWORK + "/:projectId"}>
            <ProtectedComponent
              Component={AlicloudDesignAccountFramework}
            ></ProtectedComponent>
          </Route>

          <Route path={ROUTES.ALICLOUD_PRELAUNCH + "/:projectId"}>
            <ProtectedComponent
              Component={AlicloudPrelaunch}
            ></ProtectedComponent>
          </Route>

          <Route path={ROUTES.ALICLOUD_CONFIGURE + "/:projectId"}>
            <ProtectedComponent
              Component={AlicloudConfigure}
            ></ProtectedComponent>
          </Route>=
          

          <Route path={ROUTES.AZURE_ENTERPRISE_CORE_ACCOUNTS + "/:projectId"}>
            <ProtectedComponent
              Component={AzureEnterpriseCoreAccount3}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.AZURE_ENVIRONMENT_STRATEGY + "/:projectId"}>
            <ProtectedComponent
              Component={AzureEnvironmentStrategy}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.AZURE_APPLICATION_ACCOUNTS + "/:projectId"}>
            <ProtectedComponent
              Component={AzureApplicationAccounts}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.AZURE_DEVELOPER_SANDBOX_ACCOUNTS + "/:projectId"}>
            <ProtectedComponent
              Component={AzureDeveloperSandboxAccounts}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.AZURE_ORGANIZATIONAL_UNITS + "/:projectId"}>
            <ProtectedComponent
              Component={AzureOrganizationalUnits}
            ></ProtectedComponent>
          </Route>
          <Route path={ROUTES.AZURE_ACCOUNTS + "/:projectId"}>
            <ProtectedComponent Component={AzureAccounts}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.AZURE_DESIGN_ACCOUNT_FRAMEWORK + "/:projectId"}>
            <ProtectedComponent
              Component={AzureDesignAccountFramework}
            ></ProtectedComponent>
          </Route>

          <Route path={ROUTES.AZURE_PRELAUNCH + "/:projectId"}>
            <ProtectedComponent
              Component={AzurePrelaunch}
            ></ProtectedComponent>
          </Route>

          <Route path={ROUTES.AZURE_CONFIGURE + "/:projectId"}>
            <ProtectedComponent
              Component={AzureConfigure}
            ></ProtectedComponent>
          </Route>

          <Route path={ROUTES.ADMIN_CLIENTS}>
            <ProtectedComponent Component={AdminClients}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.ADMIN_PROJECTS}>
            <ProtectedComponent Component={AdminProjects}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.ADMIN_USERS}>
            <ProtectedComponent Component={AdminUsers}></ProtectedComponent>
          </Route>
          <Route path={ROUTES.ADMIN_LOGS}>
            <ProtectedComponent Component={Logs}></ProtectedComponent>
          </Route>

          <Route path={ROUTES.CONTACT}>
            <Contact></Contact>
          </Route>

          <Route path={ROUTES.CAREERS}>
            <Careers></Careers>
          </Route>

          <Route path={ROUTES.LANDING}>
            <NewHome></NewHome>
          </Route>

          <Route>
            <NotFound></NotFound>
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default App;
