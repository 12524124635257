import React, { useState, useEffect, useRef } from "react";
import update from "immutability-helper";

import axios from "axios";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import {
  validate1to10AlphaNumeric,
  validateAccountId,
  validateAccountName,
  validateDomainName,
  validateIPAddress,
  validateUrl,
  validateTagKey
} from "../../../../helpers/validator";
import useServiceAccounts from "../../../../hooks/useServiceAccounts";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import { getError } from "../../../../helpers/errorHelper";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/infrastructure/imagemanagement";
const AWS_PRIMARY_REGION_PREFIX = "/aws/network/aws/awsprimaryregion";
const FILE_SIZE_LIMIT_IN_BYTE = 1000000;
const UPLOAD_FOLDER = "templates";

const LOOKUP = {
  amazonlinux: ["Amazon Linux 2"],
  windows: [
    "Microsoft Windows Server 1909",
    "Microsoft Windows Server 2004",
    "Microsoft Windows Server 2012R2",
    "Microsoft Windows Server 2016",
    "Microsoft Windows Server 2019",
  ],
  ubuntu: ["Ubuntu 16", "Ubuntu 18", "Ubuntu 20"],
  centos: ["CentOS Linux 7", "CentOS 7", "CentOS Linux 8"],
  rhel: ["Red Hat Enterprise Linux 7", "Red Hat Enterprise Linux 8"],
  sles: ["SLES 12", "SLES 15"],
};

const getInitialFormData = (data, images) => {
  const initialFormData = {
    deployimagemanagement: {
      value: data[`${PREFIX}/deployimagemanagement`] || "no",
    },
    imagepipelineaccount: {
      value: data[`${PREFIX}/imagepipelineaccount`] || "",
    },
    buildcomponents_componentslist:
      data[`${PREFIX}/buildcomponents/componentslist`] &&
      data[`${PREFIX}/buildcomponents/componentslist`].length > 0
        ? data[`${PREFIX}/buildcomponents/componentslist`].split(",")
        : [],
    buildcomponents_tenableagentlinkingkey: {
      value: data[`${PREFIX}/buildcomponents/tenable/agentlinkingkey`] || "",
      isValid: data[`${PREFIX}/buildcomponents/tenable/agentlinkingkey`]
        ? true
        : false,
    },
    buildcomponents_splunkdeploymentserverfqdn: {
      value: data[`${PREFIX}/buildcomponents/splunk/deploymentserverfqdn`] || "",
      isValid: data[`${PREFIX}/buildcomponents/splunk/deploymentserverfqdn`]
        ? true
        : false,
    },

    buildcomponents_mcafeeaccountid: {
      value: data[`${PREFIX}/buildcomponents/mcafee/accountid`] || "",
      isValid: data[`${PREFIX}/buildcomponents/mcafee/accountid`] ? true : false,
    },

    buildcomponents_appdynamicsaccountid: {
      value: data[`${PREFIX}/buildcomponents/appdynamics/accountid`] || "",
      isValid: data[`${PREFIX}/buildcomponents/appdynamics/accountid`]
        ? true
        : false,
    },

    buildcomponents_datadogsite: {
      value: data[`${PREFIX}/buildcomponents/datadog/site`] || "",
      isValid: data[`${PREFIX}/buildcomponents/datadog/site`] ? true : false,
    },

    buildcomponents_datadogapikey: {
      value: data[`${PREFIX}/buildcomponents/datadog/apikey`] || "",
      isValid: data[`${PREFIX}/buildcomponents/datadog/apikey`] ? true : false,
    },

    buildcomponents_signalfxrealm: {
      value: data[`${PREFIX}/buildcomponents/signalfx/realm`] || "",
      isValid: data[`${PREFIX}/buildcomponents/signalfx/realm`] ? true : false,
    },

    buildcomponents_signalfxapitoken: {
      value: data[`${PREFIX}/buildcomponents/signalfx/apitoken`] || "",
      isValid: data[`${PREFIX}/buildcomponents/signalfx/apitoken`]
        ? true
        : false,
    },

    imagepipelines: [createImagePipeline()],

    deployamicompliancecheck: {
      value: data[`${PREFIX}/deployamicompliancecheck`] || "yes",
    },
    deploycontinuousamivulnerabilityassessments: {
      value:
        data[`${PREFIX}/deploycontinuousamivulnerabilityassessments`] || "yes",
    },

    images: images || []
  };

  if (data[`${PREFIX}/imagepipelineslist`]) {
    initialFormData.imagepipelineslist = data[
      `${PREFIX}/imagepipelineslist`
    ].split(",");
    initialFormData.imagepipelines = [];

    initialFormData.imagepipelineslist.forEach((imagepipeline) => {
      let sourceimagearn = "",
        sourceimagearnselection = "usethis";

      if(data[`${PREFIX}/${imagepipeline}/sourceimagearn`]){
        const sourceimagearnArray = data[`${PREFIX}/${imagepipeline}/sourceimagearn`].split("/");

        if(sourceimagearnArray[sourceimagearnArray.length - 1] !== "x.x.x"){
          sourceimagearn = sourceimagearnArray.join("/");
          sourceimagearnselection = "usethis";
        }
        else{
          console.log(`sourceimageos: ${data[`${PREFIX}/${imagepipeline}/sourceimageos`]}`);;
          if(data[`${PREFIX}/${imagepipeline}/sourceimageos`] !== ""){
            console.log(sourceimagearnArray.slice(0, sourceimagearnArray.length - 1).join("/"));
            const selected = initialFormData.images.find(image => image.Arn.startsWith(sourceimagearnArray.slice(0, sourceimagearnArray.length - 1).join("/")))

            console.log(`selected: ${JSON.stringify(selected)}`);

            if(selected){
              sourceimagearn = selected.Arn;
            }
          }

          sourceimagearnselection = "uselatest";
        }
      }

      initialFormData.imagepipelines.push({
        name: {
          value: data[`${PREFIX}/${imagepipeline}/imagepipelinename`] || "",
          isValid: data[`${PREFIX}/${imagepipeline}/imagepipelinename`]
            ? true
            : false,
        },
        sourceimageos: {
          value: data[`${PREFIX}/${imagepipeline}/sourceimageos`] || "",
        },
        sourceimagearn: {
          value: sourceimagearn,
        },
        sourceimagearnselection: {
          value: sourceimagearnselection,
        },
        componentslist: data[`${PREFIX}/${imagepipeline}/componentslist`]
          ? data[`${PREFIX}/${imagepipeline}/componentslist`].split(",")
          : [],
        shareami: {
          value: data[`${PREFIX}/${imagepipeline}/shareami`] || "yes",
        },
      });
    });
  }

  return initialFormData;
};

const createImagePipeline = () => {
  return {
    name: {
      value: "",
      isValid: false,
    },
    sourceimageos: {
      value: "",
    },
    sourceimagearn: {
      value: "",
    },
    sourceimagearnselection: {
      value: "usethis",
    },
    componentslist: [],
    shareami: {
      value: "yes",
    },
  };
};

const components_lookup = {
  tenableagent: "Tenable Agent",
  amazoninspector: "Amazon Inspector",
  splunkagent: "Splunk Agent",
  crowdstrikeagent: "Crowdstrike Agent",
  trendmicroagent: "Trendmicro Agent",
  mcafeeagent: "McAfee Agent",
  awsssmagent: "AWS SSM Agent",
  darktraceagent: "Darktrace Agent",
  appdynamicsagent: "AppDynamics Agent",
  datadogagent: "Datadog Agent",
  signalfxagent: "Signal FX Agent",
};

const ImageManagement = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);
  const awsAmplify = useAwsAmplify();

  const serviceAccounts = useServiceAccounts(projectId);

  const [xmlFileLinux, setXmlFile] = useState(null);
  const [xmlFileWindows, setXmlFileWindows] = useState(null);
  const [existingXmlFile, setExistingXmlFile] = useState(null);
  const [existingXmlFileWindows, setExistingXmlFileWindows] = useState(null);

  const fileInputRef = useRef(null);
  const winFileInputRef = useRef(null);

  const [showPwd, setShowPwd] = useState(false);
  const [showPwd1, setShowPwd1] = useState(false);
  const [showPwd2, setShowPwd2] = useState(false);

  const [primaryRegion, setPrimaryRegion] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const primaryRegionResult = await awsAmplify.loadProjectData(projectId, AWS_PRIMARY_REGION_PREFIX);

        console.log(`primaryRegionResult`);
        console.log(primaryRegionResult);
        const primaryRegion = primaryRegionResult.data[AWS_PRIMARY_REGION_PREFIX];

        console.log(primaryRegion);

        if(primaryRegion){
          setPrimaryRegion(primaryRegion);
        }
        else{
          setIsLoading(false);
          return;
        }

        const imagesResp = await awsAmplify.getImages(projectId);

        console.log('getImages');
        console.log(imagesResp);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);

        setEmpty(result.data.empty);

        setFormData(convertBEToFE(result.data, imagesResp.data.getImages));

        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);

        const errorMsg = getError(err, setErrorMsg);

        if(errorMsg === "You have no access"){
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      let xmlLinuxFileName = null;
      let xmlWindowsFileName = null;

      if (xmlFileLinux && xmlFileLinux.size < FILE_SIZE_LIMIT_IN_BYTE &&
        formData.buildcomponents_componentslist.indexOf("trendmicroagent") !== -1) {
        const initiateUploadResult = await awsAmplify.initiateUpload(
          projectId,
          UPLOAD_FOLDER,
          xmlFileLinux.name
        );
       
        const uploadResult = await axios.put(
          initiateUploadResult.data.signedUrl,
          xmlFileLinux,
          {
            headers: {
              "Content-Type": xmlFileLinux.type,
            },
          }
        );

        console.log(uploadResult);

        if (uploadResult.status === 200) {
          xmlLinuxFileName = xmlFileLinux.name;
        }
      }

      if (xmlFileWindows && xmlFileWindows.size < FILE_SIZE_LIMIT_IN_BYTE  &&
        formData.buildcomponents_componentslist.indexOf("trendmicroagent") !==-1) {
        const initiateUploadResult = await awsAmplify.initiateUpload(
          projectId,
          UPLOAD_FOLDER,
          xmlFileWindows.name
        );
       
        const winUploadResult = await axios.put(
          initiateUploadResult.data.signedUrl,
          xmlFileWindows,
          {
            headers: {
              "Content-Type": xmlFileWindows.type,
            },
          }
        );

        console.log(winUploadResult);

        if (winUploadResult.status === 200) {
          xmlWindowsFileName = xmlFileWindows.name;
        }
      }

      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(xmlLinuxFileName,xmlWindowsFileName),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      if(err.response)
        setErrorMsg(err.response.data.message);
      else
        setErrorMsg(err && err.message);
    }
  };

  const convertBEToFE = (data, images) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data, images);

    if (data[`${PREFIX}/buildcomponents/trendmicro/linuxinstallationscriptfile`]) {
      setXmlFile({
        name: data[
          `${PREFIX}/buildcomponents/trendmicro/linuxinstallationscriptfile`
        ],
      });
      setExistingXmlFile({
        name: data[
          `${PREFIX}/buildcomponents/trendmicro/linuxinstallationscriptfile`
        ],
      });
    }

    if (data[`${PREFIX}/buildcomponents/trendmicro/windowsinstallationscriptfile`]) {
      setXmlFileWindows({
        name: data[
          `${PREFIX}/buildcomponents/trendmicro/windowsinstallationscriptfile`
        ],
      });
      setExistingXmlFileWindows({
        name: data[
          `${PREFIX}/buildcomponents/trendmicro/Windowsinstallationscriptfile`
        ],
      });
    }

    return fe;
  };

  const convertFEToBE = (xmlLinuxFileName,xmlWindowsFileName) => {
    const be = {
      [`${PREFIX}/deployimagemanagement`]: formData.deployimagemanagement.value,
    };

    if (formData.deployimagemanagement.value === "yes") {
      if (formData.imagepipelineaccount.value !== "") {
        be[`${PREFIX}/imagepipelineaccount`] =
          formData.imagepipelineaccount.value;
      }

      if (formData.buildcomponents_componentslist.length > 0) {
        be[
          `${PREFIX}/buildcomponents/componentslist`
        ] = formData.buildcomponents_componentslist.join(",");
      }

      if (
        formData.buildcomponents_componentslist.indexOf("tenableagent") !== -1
      ) {
        if (formData.buildcomponents_tenableagentlinkingkey.isValid) {
          be[`${PREFIX}/buildcomponents/tenable/agentlinkingkey`] =
            formData.buildcomponents_tenableagentlinkingkey.value;
        }
      }

      if (
        formData.buildcomponents_componentslist.indexOf("splunkagent") !== -1
      ) {
        if (formData.buildcomponents_splunkdeploymentserverfqdn.isValid) {
          be[`${PREFIX}/buildcomponents/splunk/deploymentserverfqdn`] =
            formData.buildcomponents_splunkdeploymentserverfqdn.value;
        }
      }

      if (
        formData.buildcomponents_componentslist.indexOf("trendmicroagent") !==
        -1
      ) {
        if (xmlLinuxFileName) {
          be[`${PREFIX}/buildcomponents/trendmicro/linuxinstallationscriptfile`] = `${xmlLinuxFileName}`;
        } else {
          if (existingXmlFile) {
            be[
              `${PREFIX}/buildcomponents/trendmicro/linuxinstallationscriptfile`
            ] = `${existingXmlFile.name}`;
          }
        }

        if (xmlWindowsFileName) {
          be[`${PREFIX}/buildcomponents/trendmicro/windowsinstallationscriptfile`] = `${xmlWindowsFileName}`;
        } else {
          if (existingXmlFileWindows) {
            be[
              `${PREFIX}/buildcomponents/trendmicro/windowsinstallationscriptfile`
            ] = `${existingXmlFileWindows.name}`;
          }
        }

        /* if (formData.buildcomponents_trendmicroinstallationscriptfile.isValid) {
          be[`${PREFIX}/buildcomponents/trendmicroinstallationscriptfile`] =
            formData.buildcomponents_trendmicroinstallationscriptfile.value;
        } */
      }

      if (
        formData.buildcomponents_componentslist.indexOf("mcafeeagent") !== -1
      ) {
        if (formData.buildcomponents_mcafeeaccountid.isValid) {
          be[`${PREFIX}/buildcomponents/mcafee/accountid`] =
            formData.buildcomponents_mcafeeaccountid.value;
        }
      }

      if (
        formData.buildcomponents_componentslist.indexOf("appdynamicsagent") !==
        -1
      ) {
        if (formData.buildcomponents_appdynamicsaccountid.isValid) {
          be[`${PREFIX}/buildcomponents/appdynamics/accountid`] =
            formData.buildcomponents_appdynamicsaccountid.value;
        }
      }

      if (
        formData.buildcomponents_componentslist.indexOf("datadogagent") !== -1
      ) {
        if (formData.buildcomponents_datadogsite.isValid) {
          be[`${PREFIX}/buildcomponents/datadog/site`] =
            formData.buildcomponents_datadogsite.value;
        }

        if (formData.buildcomponents_datadogapikey.isValid) {
          be[`${PREFIX}/buildcomponents/datadog/apikey`] =
            formData.buildcomponents_datadogapikey.value;
        }
      }

      if (
        formData.buildcomponents_componentslist.indexOf("signalfxagent") !== -1
      ) {
        if (formData.buildcomponents_signalfxrealm.isValid) {
          be[`${PREFIX}/buildcomponents/signalfx/realm`] =
            formData.buildcomponents_signalfxrealm.value;
        }

        if (formData.buildcomponents_signalfxapitoken.isValid) {
          be[`${PREFIX}/buildcomponents/signalfx/apitoken`] =
            formData.buildcomponents_signalfxapitoken.value;
        }
      }

      const imagepipelines = [];

      formData.imagepipelines.forEach((imagepipeline, idx) => {
        //if (imagepipeline.name.isValid) {
          const key = `imagepipeline${idx + 1}`;

          imagepipelines.push(key);

          if (imagepipeline.name.isValid) {
            be[`${PREFIX}/${key}/imagepipelinename`] =
                imagepipeline.name.value;
          }

          if (imagepipeline.sourceimageos.value !== "") {
            be[`${PREFIX}/${key}/sourceimageos`] =
              imagepipeline.sourceimageos.value;
          }

          if(imagepipeline.sourceimagearnselection.value === "usethis"){
            if(imagepipeline.sourceimagearn.value !== ""){
            be[`${PREFIX}/${key}/sourceimagearn`] = imagepipeline.sourceimagearn.value;
          }
          }

          if(imagepipeline.sourceimagearnselection.value === "uselatest"){
            if(imagepipeline.sourceimagearn.value !== ""){
              const sourceimagearnArray = imagepipeline.sourceimagearn.value.split("/");
              sourceimagearnArray[sourceimagearnArray.length - 1] = "x.x.x";

              be[`${PREFIX}/${key}/sourceimagearn`] = sourceimagearnArray.join("/");
            }
          }

          if (imagepipeline.shareami.value !== "") {
            be[`${PREFIX}/${key}/shareami`] =
              imagepipeline.shareami.value;
          }

          if (
            imagepipeline.componentslist.length > 0 &&
            imagepipeline.componentslist.filter(
              (c) => formData.buildcomponents_componentslist.indexOf(c) !== -1
            ).length > 0
          ) {
            be[
              `${PREFIX}/${key}/componentslist`
            ] = imagepipeline.componentslist
              .filter(
                (c) => formData.buildcomponents_componentslist.indexOf(c) !== -1
              )
              .join(",");
          }
        //}
      });

      if (imagepipelines.length > 0) {
        be[`${PREFIX}/imagepipelineslist`] = imagepipelines.join(",");
      }

      be[`${PREFIX}/deployamicompliancecheck`] =
        formData.deployamicompliancecheck.value;
      be[`${PREFIX}/deploycontinuousamivulnerabilityassessments`] =
        formData.deploycontinuousamivulnerabilityassessments.value;
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const exists = (key, value) => {
    return formData[key].indexOf(value) !== -1;
  };

  const add = (key, value) => {
    setIsDirty(true);

    setFormData((state) => update(state, { [key]: { $push: [value] } }));
  };

  const remove = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: { $splice: [[state[key].indexOf(value), 1]] },
      })
    );
  };

  const modifyArray = (key, value) => {
    if (exists(key, value)) {
      remove(key, value);
    } else {
      add(key, value);
    }
  };

  const addImagepipeline = () => {
    if(formData.imagepipelines.length < 5)
    {
      setIsDirty(true);

      setFormData((state) =>
        update(state, { imagepipelines: { $push: [createImagePipeline()] } })
      );
    }
    else
    {
      setErrorMsg("Maximum of 5 Image Pipelines are allowed");
    }
  };

  const removeImagepipeline = (idx) => {
    if (formData.imagepipelines.length > 1) {
      setIsDirty(true);

      setFormData((state) =>
        update(state, { imagepipelines: { $splice: [[idx, 1]] } })
      );
    }
  };

  const onFieldChange = (value, key, idx) => {
    setErrorMsg(null);
    setIsDirty(true);

    if (idx !== undefined) {
      setFormData((state) =>
        update(state, {
          imagepipelines: {
            [idx]: {
              [key]: {
                value: {
                  $set: value,
                },
              },
            },
          },
        })
      );
    } else {
      setFormData((state) =>
        update(state, { [key]: { value: { $set: value } } })
      );
    }
  };

  const onFieldBlur = (validateFunc, value, key, idx) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      if (idx !== undefined) {
        setFormData((state) =>
          update(state, {
            imagepipelines: {
              [idx]: {
                [key]: { isValid: { $set: errorMsg === undefined } },
              },
            },
          })
        );
      } else {
        setFormData((state) =>
          update(state, {
            [key]: { isValid: { $set: errorMsg === undefined } },
          })
        );
      }

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      if (idx !== undefined) {
        setFormData((state) =>
          update(state, {
            imagepipelines: {
              [idx]: {
                [key]: { isValid: { $set: false } },
              },
            },
          })
        );
      } else {
        setFormData((state) =>
          update(state, { [key]: { isValid: { $set: false } } })
        );
      }
    }
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Infrasturcture</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Infrastructure"
                isDirty={isDirty}
                dashboardRoute={ROUTES.INFRASTRUCTURE_DIRECTORIES}
                designRoute={ROUTES.DESIGN_INFRASTRUCTURE}
              ></DomainMenu>
              <fieldset
                disabled={disabled}
                className="mainArea fitDeviceHeight"
              >
                <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 ">
                  <div className="row d-flex align-items-center">
                    <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                      <h3>Image Management</h3>
                    </div>
                    <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                      <div className="d-inline-flex yes-no-area align-items-center">
                        <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                          Deploy Image Management Solution
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                          <RadioGroup
                            field="deployimagemanagement"
                            value={formData.deployimagemanagement.value}
                            onChange={updateRadio}
                            isLarge={true}
                          ></RadioGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  {!primaryRegion && (
                      <span className="warning">
                        This depends on the AWS Primary Region information in 'AWS Environment" section of 'Network' domain that is yet to be provided.
                      </span>
                  )}

                  {empty && (
                    <NoInitialData />
                  )}

                  {formData.deployimagemanagement.value === "yes" && (
                    <div className="show-hide-content">
                      <div className="d-inline-flex align-items-center w-100 px-3 row">
                        <div className="col-md-2 my-1 p-0">
                          <span className="mb-0 font-12">
                            Image Pipeline Account
                          </span>
                        </div>
                        <div className="col-md-10 flex-wrap d-inline-flex">
                          <div className="mr-3 my-1">
                            <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                              <select
                                className="form-control gray"
                                value={formData.imagepipelineaccount.value}
                                onChange={(e) => {
                                  onFieldChange(
                                    e.target.value,
                                    "imagepipelineaccount"
                                  );
                                }}
                              >
                                <option value="">Select</option>
                                {serviceAccounts.shared.map((sa, idxSa) => (
                                  <option key={idxSa} value={sa}>
                                    {sa}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mainContentArea my-1 py-1 p-relative">
                        <div className="">
                          <div className=" row  px-md-2 px-0  justify-content-between">
                            <div className="col-12 my-1">
                              <p className="m-0 p-0 font-12">
                                Deploy Build & Test Components
                              </p>
                            </div>

                            <div className="d-flex col-lg-6 col-12  pl-md-0 px-1 specialHalfColumn">
                              <div className="d-flex flex-column w-100">
                                <div className=" d-inline-flex formRow  px-md-2 px-3 my-1 align-items-center justify-content-between">
                                  <div className="showRightElements one form-line my-lg-0 my-1 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "buildcomponents_componentslist",
                                          "tenableagent"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "buildcomponents_componentslist",
                                            "tenableagent"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">
                                      Tenable Agent
                                    </span>
                                  </div>
                                  {exists(
                                    "buildcomponents_componentslist",
                                    "tenableagent"
                                  ) && (
                                    <div className="rightElement d-inline-flex my-lg-0 my-1 form-line two">
                                      <div className="d-flex align-items-center mr-2 text-nowrap green-style">
                                        Agent Linking Key
                                      </div>
                                      <div className="p-relative d-flex align-items-center">
                                        <button
                                          disabled={disabled}
                                          className="showableInput"
                                          type="button"
                                          onClick={() => setShowPwd(!showPwd)}
                                        ></button>
                                        <input
                                          type={showPwd ? "text" : "password"}
                                          className={`w-md-100 form-control ${
                                            formData
                                              .buildcomponents_tenableagentlinkingkey
                                              .value !== "" &&
                                            formData
                                              .buildcomponents_tenableagentlinkingkey
                                              .isValid === false
                                              ? "invalid"
                                              : ""
                                          }`}
                                          placeholder=""
                                          value={
                                            formData
                                              .buildcomponents_tenableagentlinkingkey
                                              .value
                                          }
                                          onChange={(e) =>
                                            onFieldChange(
                                              e.target.value,
                                              "buildcomponents_tenableagentlinkingkey"
                                            )
                                          }
                                          onBlur={(e) =>
                                            onFieldBlur(
                                              validateAccountName,
                                              e.target.value,
                                              "buildcomponents_tenableagentlinkingkey"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                                  <div className="one form-line my-lg-0 my-1 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "buildcomponents_componentslist",
                                          "amazoninspector"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "buildcomponents_componentslist",
                                            "amazoninspector"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">
                                      Amazon Inspector
                                    </span>
                                  </div>
                                </div>

                                <div className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                                  <div className="showRightElements one form-line my-lg-0 my-1 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "buildcomponents_componentslist",
                                          "splunkagent"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "buildcomponents_componentslist",
                                            "splunkagent"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">
                                      Splunk Agent
                                    </span>
                                  </div>
                                  {exists(
                                    "buildcomponents_componentslist",
                                    "splunkagent"
                                  ) && (
                                    <div className="rightElement d-inline-flex my-lg-0 my-1 form-line two">
                                      <div className="d-flex align-items-center mr-2  green-style text-nowrap">
                                        Deployment Server FQDN/IP
                                      </div>
                                      <input
                                        type="text"
                                        className={`w-md-100 form-control ${
                                          formData
                                            .buildcomponents_splunkdeploymentserverfqdn
                                            .value !== "" &&
                                          formData
                                            .buildcomponents_splunkdeploymentserverfqdn
                                            .isValid === false
                                            ? "invalid"
                                            : ""
                                        }`}
                                        placeholder=""
                                        value={
                                          formData
                                            .buildcomponents_splunkdeploymentserverfqdn
                                            .value
                                        }
                                        onChange={(e) =>
                                          onFieldChange(
                                            e.target.value,
                                            "buildcomponents_splunkdeploymentserverfqdn"
                                          )
                                        }
                                        onBlur={(e) => {
                                          const validateDomainNameAndIPAddress = (
                                            v
                                          ) => {
                                            let errorMsg = validateDomainName(
                                              v
                                            );

                                            if (errorMsg) {
                                              errorMsg = validateIPAddress(v);

                                              if (errorMsg) {
                                                return "must be domain format or ip address format";
                                              } else {
                                                return errorMsg;
                                              }
                                            } else {
                                              return errorMsg;
                                            }
                                          };
                                          onFieldBlur(
                                            validateDomainNameAndIPAddress,
                                            e.target.value,
                                            "buildcomponents_splunkdeploymentserverfqdn"
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>

                                <div className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                                  <div className="one my-lg-0 my-1 form-line d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "buildcomponents_componentslist",
                                          "crowdstrikeagent"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "buildcomponents_componentslist",
                                            "crowdstrikeagent"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">
                                      Crowdstrike Agent
                                    </span>
                                  </div>
                                </div>

                                <div className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                                  <div className="showRightElements one form-line my-lg-0 my-1 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "buildcomponents_componentslist",
                                          "trendmicroagent"
                                        )}
                                        onChange={(e) =>
                                          modifyArray(
                                            "buildcomponents_componentslist",
                                            "trendmicroagent"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">
                                      Trendmicro Agent
                                    </span>
                                  </div>

                                  {exists(
                                    "buildcomponents_componentslist",
                                    "trendmicroagent"
                                  ) && (
                                    <>
                                    <div className="rightElement justify-content-end d-inline-flex mr-0 pr-0 align-items-center p-relative">
                                      <label
                                        className="d-inline-flex mb-0 align-items-center mr-2"
                                        htmlFor="win-upload-xml"
                                      >
                                        <span className="mr-1 green-style">
                                          Windows Installation Script File
                                        </span>
                                        <img src="../images/upload.svg" />
                                      </label>
                                      <button
                                        disabled={disabled || !xmlFileWindows}
                                        className="closableInput"
                                        type="button"
                                        onClick={() => {
                                          setIsDirty(true);
                                          setXmlFileWindows(null);
                                          setExistingXmlFileWindows(null);
                                        }}
                                      ></button>
                                      <input
                                        type="file"
                                        style={{
                                          width: "5px",
                                          display: "none",
                                        }}
                                        name="win-xml"
                                        className=" form-control bg-transparent"
                                        id="win-upload-xml"
                                        ref={winFileInputRef}
                                        onChange={(event) => {
                                          const winFile = event.target.files[0];

                                          if (
                                            winFile.size > FILE_SIZE_LIMIT_IN_BYTE
                                          ) {
                                            setErrorMsg(
                                              `${winFile.name} has exceeded ${FILE_SIZE_LIMIT_IN_BYTE} Bytes`
                                            );
                                            return;
                                          }

                                          if (winFile.type !== "text/xml") {
                                            setErrorMsg(
                                              "Only XML file is allowed"
                                            );
                                            return;
                                          }
                                          const winFileName = "infrastructure-imagemanagement-buildcomponent-trendmicroagent-windows.xml";
                                          var winBlob = winFile.slice(0, winFile.size, winFile.type); 
                                          var newWinFile = new File([winBlob], winFileName, {type: ''});
                                          setXmlFileWindows(newWinFile);

                                          setIsDirty(true);
                                        }}
                                      />
                                      <div className=" bg-gray border-10  p-relative d-inline-flex align-items-center justify-content-end">
                                        <input
                                          placeholder={
                                            xmlFileWindows ? xmlFileWindows.name.split('-').pop() : ""
                                          }
                                          type="text"
                                          disabled 
                                          className="w-md-100 form-control bg-white"
                                        />
                                      </div>
                                    </div>
                                  
                                    <div className="rightElement justify-content-end d-inline-flex mr-0 pr-0 align-items-center ">
                                      <label
                                        className="d-inline-flex mb-0 align-items-center mr-2"
                                        htmlFor="upload-xml"
                                      >
                                        <span className="mr-1 green-style">
                                          Linux Installation Script File
                                        </span>
                                        <img src="../images/upload.svg" />
                                      </label>
                                      <button
                                        disabled={disabled || !xmlFileLinux}
                                        className="closableInput"
                                        type="button"
                                        onClick={() => {
                                          setIsDirty(true);
                                          setXmlFile(null);
                                          setExistingXmlFile(null);
                                        }}
                                      ></button>
                                      <input
                                        type="file"
                                        style={{
                                          width: "5px",
                                          display: "none",
                                        }}
                                        name="xml"
                                        className=" form-control bg-transparent"
                                        id="upload-xml"
                                        ref={fileInputRef}
                                        onChange={(e) => {
                                          var file = e.target.files[0];

                                          if (
                                            file.size > FILE_SIZE_LIMIT_IN_BYTE
                                          ) {
                                            setErrorMsg(
                                              `${file.name} has exceeded ${FILE_SIZE_LIMIT_IN_BYTE} Bytes`
                                            );
                                            return;
                                          }

                                          if (file.type !== "text/xml") {
                                            setErrorMsg(
                                              "Only XML file is allowed"
                                            );
                                            return;
                                          }
                                          const linuxFileName = "infrastructure-imagemanagement-buildcomponent-trendmicroagent-linux.xml";
                                          var blob = file.slice(0, file.size, file.type); 
                                          var newFile = new File([blob], linuxFileName, {type: ''});
                                          setXmlFile(newFile);

                                          setIsDirty(true);
                                        }}
                                      />
                                      <div className=" bg-gray border-10  p-relative d-inline-flex align-items-center justify-content-end"
                                      >
                                        <input
                                          placeholder={
                                            xmlFileLinux ? xmlFileLinux.name.split('-').pop() : ""
                                          }
                                          type="text"
                                          disabled
                                          className="w-md-100 form-control bg-white"
                                        />
                                      </div>
                                    </div>
                                    </>
                                  )}
                                </div>

                                <div className=" d-inline-flex formRow  px-md-2 px-3 my-1 align-items-center justify-content-between">
                                  <div className="showRightElements one form-line my-lg-0 my-1  d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "buildcomponents_componentslist",
                                          "mcafeeagent"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "buildcomponents_componentslist",
                                            "mcafeeagent"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">
                                      McAfee Agent
                                    </span>
                                  </div>
                                  {exists(
                                    "buildcomponents_componentslist",
                                    "mcafeeagent"
                                  ) && (
                                    <div className="rightElement d-inline-flex my-lg-0 my-1 form-line two">
                                      <div className="d-flex align-items-center mr-3  green-style text-nowrap">
                                        Account Id
                                      </div>
                                      <input
                                        type="text"
                                        className={`w-md-100 form-control ${
                                          formData
                                            .buildcomponents_mcafeeaccountid
                                            .value !== "" &&
                                          formData
                                            .buildcomponents_mcafeeaccountid
                                            .isValid === false
                                            ? "invalid"
                                            : ""
                                        }`}
                                        placeholder=""
                                        value={
                                          formData
                                            .buildcomponents_mcafeeaccountid
                                            .value
                                        }
                                        onChange={(e) =>
                                          onFieldChange(
                                            e.target.value,
                                            "buildcomponents_mcafeeaccountid"
                                          )
                                        }
                                        onBlur={(e) =>
                                          onFieldBlur(
                                            validateAccountId,
                                            e.target.value,
                                            "buildcomponents_mcafeeaccountid"
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="d-flex col-lg-6 col-12  pl-md-0 px-1 specialHalfColumn">
                              <div className="d-flex flex-column w-100">
                                <div className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                                  <div className="showRightElements one my-lg-0 my-1 form-line d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "buildcomponents_componentslist",
                                          "awsssmagent"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "buildcomponents_componentslist",
                                            "awsssmagent"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">
                                      AWS SSM Agent
                                    </span>
                                  </div>
                                </div>

                                <div className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                                  <div className="one form-line my-lg-0 my-1 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "buildcomponents_componentslist",
                                          "darktraceagent"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "buildcomponents_componentslist",
                                            "darktraceagent"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">
                                      Darktrace Agent
                                    </span>
                                  </div>
                                </div>

                                <div className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                                  <div className="showRightElements one form-line my-lg-0 my-1 d-inline-flex align-items-center">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "buildcomponents_componentslist",
                                          "appdynamicsagent"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "buildcomponents_componentslist",
                                            "appdynamicsagent"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">
                                      AppDynamics Agent
                                    </span>
                                  </div>
                                  {exists(
                                    "buildcomponents_componentslist",
                                    "appdynamicsagent"
                                  ) && (
                                    <div className="rightElement d-inline-flex my-lg-0 my-1 form-line two">
                                      <div className="d-flex align-items-center mr-3   green-style text-nowrap">
                                        Account Id
                                      </div>
                                      <input
                                        type="text"
                                        className={`w-md-100 form-control ${
                                          formData
                                            .buildcomponents_appdynamicsaccountid
                                            .value !== "" &&
                                          formData
                                            .buildcomponents_appdynamicsaccountid
                                            .isValid === false
                                            ? "invalid"
                                            : ""
                                        }`}
                                        placeholder=""
                                        value={
                                          formData
                                            .buildcomponents_appdynamicsaccountid
                                            .value
                                        }
                                        onChange={(e) =>
                                          onFieldChange(
                                            e.target.value,
                                            "buildcomponents_appdynamicsaccountid"
                                          )
                                        }
                                        onBlur={(e) =>
                                          onFieldBlur(
                                            validateAccountId,
                                            e.target.value,
                                            "buildcomponents_appdynamicsaccountid"
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                </div>

                                <div className=" d-inline-flex formRow flex-wrap px-md-2 px-3 my-1 align-items-center justify-content-between">
                                  <div className="showRightElements one my-lg-0 my-1 form-line d-inline-flex align-items-center flex-1 ">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "buildcomponents_componentslist",
                                          "datadogagent"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "buildcomponents_componentslist",
                                            "datadogagent"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel text-nowrap">
                                      Datadog Agent
                                    </span>
                                  </div>
                                  {exists(
                                    "buildcomponents_componentslist",
                                    "datadogagent"
                                  ) && (
                                    <>
                                      <div className="rightElement d-inline-flex my-lg-0 my-1 form-line two flex-1 flex-1 ">
                                        <div className="d-flex align-items-center mr-3   green-style text-nowrap">
                                          Datadog Site
                                        </div>
                                        <input
                                          type="text"
                                          className={`w-md-100 form-control ${
                                            formData.buildcomponents_datadogsite
                                              .value !== "" &&
                                            formData.buildcomponents_datadogsite
                                              .isValid === false
                                              ? "invalid"
                                              : ""
                                          }`}
                                          placeholder=""
                                          value={
                                            formData.buildcomponents_datadogsite
                                              .value
                                          }
                                          onChange={(e) =>
                                            onFieldChange(
                                              e.target.value,
                                              "buildcomponents_datadogsite"
                                            )
                                          }
                                          onBlur={(e) =>
                                            onFieldBlur(
                                              validateUrl,
                                              e.target.value,
                                              "buildcomponents_datadogsite"
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="rightElement justify-content-end d-inline-flex my-lg-0 my-1 form-line three flex-1 ">
                                        <div className="d-flex align-items-center mr-3   green-style text-nowrap">
                                          API Key
                                        </div>
                                        <div className="p-relative d-flex align-items-center">
                                          <button
                                            disabled={disabled}
                                            type="button"
                                            onClick={() =>
                                              setShowPwd1(!showPwd1)
                                            }
                                            className="showableInput"
                                          ></button>
                                          <input
                                            type={
                                              showPwd1 ? "text" : "password"
                                            }
                                            className={`w-md-100 form-control ${
                                              formData
                                                .buildcomponents_datadogapikey
                                                .value !== "" &&
                                              formData
                                                .buildcomponents_datadogapikey
                                                .isValid === false
                                                ? "invalid"
                                                : ""
                                            }`}
                                            placeholder=""
                                            value={
                                              formData
                                                .buildcomponents_datadogapikey
                                                .value
                                            }
                                            onChange={(e) =>
                                              onFieldChange(
                                                e.target.value,
                                                "buildcomponents_datadogapikey"
                                              )
                                            }
                                            onBlur={(e) =>
                                              onFieldBlur(
                                              validateTagKey,
                                                e.target.value,
                                                "buildcomponents_datadogapikey"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                                  <div className="showRightElements one my-lg-0 my-1 form-line d-inline-flex align-items-center flex-1">
                                    <label className="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(
                                          "buildcomponents_componentslist",
                                          "signalfxagent"
                                        )}
                                        onChange={() =>
                                          modifyArray(
                                            "buildcomponents_componentslist",
                                            "signalfxagent"
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="switchLabel">
                                      Signal FX Agent
                                    </span>
                                  </div>
                                  {exists(
                                    "buildcomponents_componentslist",
                                    "signalfxagent"
                                  ) && (
                                    <>
                                      <div className="rightElement d-inline-flex my-lg-0 my-1 form-line two flex-1">
                                        <div className="d-flex align-items-center mr-3   green-style text-nowrap">
                                          Realm
                                        </div>
                                        <input
                                          type="text"
                                          className={`w-md-100 form-control ${
                                            formData
                                              .buildcomponents_signalfxrealm
                                              .value !== "" &&
                                            formData
                                              .buildcomponents_signalfxrealm
                                              .isValid === false
                                              ? "invalid"
                                              : ""
                                          }`}
                                          placeholder=""
                                          value={
                                            formData
                                              .buildcomponents_signalfxrealm
                                              .value
                                          }
                                          onChange={(e) =>
                                            onFieldChange(
                                              e.target.value,
                                              "buildcomponents_signalfxrealm"
                                            )
                                          }
                                          onBlur={(e) =>
                                            onFieldBlur(
                                              validate1to10AlphaNumeric,
                                              e.target.value,
                                              "buildcomponents_signalfxrealm"
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="rightElement d-inline-flex my-lg-0 my-1 form-line three flex-1 justify-content-end">
                                        <div className="d-flex align-items-center mr-3   green-style text-nowrap">
                                          API Token
                                        </div>
                                        <div className="p-relative d-flex align-items-center">
                                          <button
                                            disabled={disabled}
                                            type="button"
                                            onClick={() =>
                                              setShowPwd2(!showPwd2)
                                            }
                                            className="showableInput"
                                          ></button>
                                          <input
                                            type={
                                              showPwd2 ? "text" : "password"
                                            }
                                            className={`w-md-100 form-control ${
                                              formData
                                                .buildcomponents_signalfxapitoken
                                                .value !== "" &&
                                              formData
                                                .buildcomponents_signalfxapitoken
                                                .isValid === false
                                                ? "invalid"
                                                : ""
                                            }`}
                                            placeholder=""
                                            value={
                                              formData
                                                .buildcomponents_signalfxapitoken
                                                .value
                                            }
                                            onChange={(e) =>
                                              onFieldChange(
                                                e.target.value,
                                                "buildcomponents_signalfxapitoken"
                                              )
                                            }
                                            onBlur={(e) =>
                                              onFieldBlur(
                                                validate1to10AlphaNumeric,
                                                e.target.value,
                                                "buildcomponents_signalfxapitoken"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {formData.imagepipelines.map((item, idxItem) => (
                        <React.Fragment key={idxItem}>
                          <div className="col-12 my-1 px-0">
                            <div className="d-flex align-items-center mr-md-3 mr-0 px-0">
                              <div className="d-inline-flex form-line px-0">
                                <span className="font-12 d-flex align-items-center text-md-nowrap">
                                  Image Pipeline {idxItem + 1} Name
                                </span>
                                <input
                                  type="text"
                                  className={`form-control gray ${
                                    item.name.value !== "" &&
                                    item.name.isValid === false
                                      ? "invalid"
                                      : ""
                                  }`}
                                  placeholder=""
                                  value={item.name.value}
                                  onChange={(e) => {
                                    const value = e.target.value;

                                    for (const [
                                      idxOtherItem,
                                      otherItem,
                                    ] of formData.imagepipelines.entries()) {
                                      if (idxItem !== idxOtherItem) {
                                        if (
                                          otherItem.name.isValid &&
                                          otherItem.name.value !== ""
                                        ) {
                                          if (
                                            otherItem.name.value.toLowerCase() ===
                                            value.toLowerCase()
                                          ) {
                                            setErrorMsg(
                                              "Image pipeline name must be unique"
                                            );
                                            return false;
                                          }
                                        }
                                      }
                                    }

                                    onFieldChange(value, "name", idxItem);
                                  }}
                                  onBlur={(e) =>
                                    onFieldBlur(
                                      validateAccountName,
                                      e.target.value,
                                      "name",
                                      idxItem
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mainContentArea my-1 py-1 p-relative">
                            <div className="row">
                              <div className="col-xl-8 col-md-10">
                                <p className="my-1 font-12">Source Image OS</p>
                              </div>
                              <div className="col-xl-4 col-md-2  d-flex align-items-center my-lg-0 my-1 justify-content-end px-0">
                                <button
                                  disabled={disabled}
                                  className="closeFormRow closeFormRowBtn mx-2 four"
                                  type="button"
                                  onClick={() => removeImagepipeline(idxItem)}
                                >
                                  <img src="../images/bigfontclose.svg" />
                                </button>
                              </div>
                              <button
                                disabled={disabled}
                                className="closeFormRowMobil closeFormRowBtn my-lg-0 my-1 mx-2 four"
                                type="button"
                                onClick={() => removeImagepipeline(idxItem)}
                              >
                                <img src="../images/bigfontclose.svg" />
                              </button>
                              <div className="col-xl-2 col-lg-4 my-1 col-6 px-2 d-flex">
                                <span
                                  className={`littleContentArea ${
                                    item.sourceimageos.value === "amazonlinux"
                                      ? "activeLittleContentArea"
                                      : ""
                                  } p-2 flex-1`}
                                >
                                  <label className="container d-flex flex-column px-4 font-12 justify-content-between">
                                    <div className="p-relative">
                                      <p className="pl-4">
                                        Amazon Linux
                                        <br />{" "}
                                        <span className="font-11 text-color-gray">
                                          Amazon Linux 2
                                        </span>
                                      </p>
                                      <input
                                        type="radio"
                                        name={`sourceimageos-${idxItem}`}
                                        checked={
                                          item.sourceimageos.value ===
                                          "amazonlinux"
                                        }
                                        onChange={() => {
                                          setIsDirty(true);
                                          setFormData((state) =>
                                            update(state, {
                                              imagepipelines: {
                                                [idxItem]: {
                                                  sourceimageos: {
                                                    value: {
                                                      $set: "amazonlinux",
                                                    },
                                                  },
                                                },
                                              },
                                            })
                                          );
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                    </div>

                                    <img src="../images/amazon.svg" />
                                  </label>
                                </span>
                              </div>
                              <div className="col-xl-2 col-lg-4 my-1 col-6 px-2 d-flex">
                                <span
                                  className={`littleContentArea ${
                                    item.sourceimageos.value === "windows"
                                      ? "activeLittleContentArea"
                                      : ""
                                  } p-2 flex-1`}
                                >
                                  <label className="container d-flex flex-column px-4 font-12 justify-content-between">
                                    <div className="p-relative">
                                      <p className="pl-4">
                                        Windows
                                        <br />{" "}
                                        <span className="font-11 text-color-gray">
                                          Windows Server 1908,2012R2 2016, and
                                          2019
                                        </span>
                                      </p>
                                      <input
                                        type="radio"
                                        name={`sourceimageos-${idxItem}`}
                                        checked={
                                          item.sourceimageos.value === "windows"
                                        }
                                        onChange={() => {
                                          setIsDirty(true);
                                          setFormData((state) =>
                                            update(state, {
                                              imagepipelines: {
                                                [idxItem]: {
                                                  sourceimageos: {
                                                    value: {
                                                      $set: "windows",
                                                    },
                                                  },
                                                },
                                              },
                                            })
                                          );
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                    </div>

                                    <img src="../images/windows.svg" />
                                  </label>
                                </span>
                              </div>
                              <div className="col-xl-2 col-lg-4 my-1 col-6 px-2 d-flex">
                                <span
                                  className={`littleContentArea ${
                                    item.sourceimageos.value === "ubunu"
                                      ? "activeLittleContentArea"
                                      : ""
                                  } p-2 flex-1`}
                                >
                                  <label className="container d-flex flex-column px-4 font-12  justify-content-between">
                                    <div className="p-relative">
                                      <p className="pl-4">
                                        Ubuntu
                                        <br />{" "}
                                        <span className="font-11 text-color-gray">
                                          Ubuntu 16 and 18
                                        </span>
                                      </p>
                                      <input
                                        type="radio"
                                        name={`sourceimageos-${idxItem}`}
                                        checked={
                                          item.sourceimageos.value === "ubuntu"
                                        }
                                        onChange={() => {
                                          setIsDirty(true);
                                          setFormData((state) =>
                                            update(state, {
                                              imagepipelines: {
                                                [idxItem]: {
                                                  sourceimageos: {
                                                    value: {
                                                      $set: "ubuntu",
                                                    },
                                                  },
                                                },
                                              },
                                            })
                                          );
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                    </div>
                                    <img src="../images/ubuntu.svg" />
                                  </label>
                                </span>
                              </div>
                              <div className="col-xl-2 col-lg-4 my-1 col-6 px-2 d-flex">
                                <span
                                  className={`littleContentArea ${
                                    item.sourceimageos.value === "centos"
                                      ? "activeLittleContentArea"
                                      : ""
                                  } p-2 flex-1`}
                                >
                                  <label className="container d-flex flex-column px-4 font-12  justify-content-between">
                                    <div className="p-relative">
                                      <p className="pl-4">
                                        CentOs
                                        <br />{" "}
                                        <span className="font-11 text-color-gray">
                                          CentOs 7 and 8
                                        </span>
                                      </p>
                                      <input
                                        type="radio"
                                        name={`sourceimageos-${idxItem}`}
                                        checked={
                                          item.sourceimageos.value === "centos"
                                        }
                                        onChange={() => {
                                          setIsDirty(true);
                                          setFormData((state) =>
                                            update(state, {
                                              imagepipelines: {
                                                [idxItem]: {
                                                  sourceimageos: {
                                                    value: {
                                                      $set: "centos",
                                                    },
                                                  },
                                                },
                                              },
                                            })
                                          );
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                    </div>
                                    <img src="../images/centos.svg" />
                                  </label>
                                </span>
                              </div>
                              <div className="col-xl-2 col-lg-4 my-1 col-6 px-2 d-flex">
                                <span
                                  className={`littleContentArea ${
                                    item.sourceimageos.value === "rhel"
                                      ? "activeLittleContentArea"
                                      : ""
                                  } p-2 flex-1`}
                                >
                                  <label className="container d-flex flex-column px-4 font-12 justify-content-between">
                                    <div className="p-relative">
                                      <p className="pl-4">
                                        Red Hat Enterprise Linux (RHEL)
                                        <br />{" "}
                                        <span className="font-11 text-color-gray">
                                          RHEL 7 and 8
                                        </span>
                                      </p>
                                      <input
                                        type="radio"
                                        name={`sourceimageos-${idxItem}`}
                                        checked={
                                          item.sourceimageos.value === "rhel"
                                        }
                                        onChange={() => {
                                          setIsDirty(true);
                                          setFormData((state) =>
                                            update(state, {
                                              imagepipelines: {
                                                [idxItem]: {
                                                  sourceimageos: {
                                                    value: {
                                                      $set: "rhel",
                                                    },
                                                  },
                                                },
                                              },
                                            })
                                          );
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                    </div>
                                    <img src="../images/redhat.svg" />
                                  </label>
                                </span>
                              </div>
                              <div className="col-xl-2 col-lg-4 my-1 col-6 px-2 d-flex">
                                <span
                                  className={`littleContentArea ${
                                    item.sourceimageos.value === "sles"
                                      ? "activeLittleContentArea"
                                      : ""
                                  } p-2 flex-1`}
                                >
                                  <label className="container d-flex flex-column px-4 font-12  justify-content-between">
                                    <div className="p-relative">
                                      <p className="pl-4">
                                        SUSE Linux Enterprise Server
                                        <br />{" "}
                                        <span className="font-11 text-color-gray">
                                          SLES 15
                                        </span>
                                      </p>
                                      <input
                                        type="radio"
                                        name={`sourceimageos-${idxItem}`}
                                        checked={
                                          item.sourceimageos.value === "sles"
                                        }
                                        onChange={() => {
                                          setIsDirty(true);
                                          setFormData((state) =>
                                            update(state, {
                                              imagepipelines: {
                                                [idxItem]: {
                                                  sourceimageos: {
                                                    value: {
                                                      $set: "sles",
                                                    },
                                                  },
                                                },
                                              },
                                            })
                                          );
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                    </div>
                                    <img src="../images/suse.svg" />
                                  </label>
                                </span>
                              </div>
                            </div>

                            <div className="">
                              <div className="row px-md-2 px-0 align-items-center">
                                <div className="col-12  my-1">
                                  <div className="d-inline-flex align-items-center w-100 row">
                                    <div className="col-md-2 my-1 p-0">
                                      <span className="mb-0 font-12">
                                        Source Image
                                      </span>
                                    </div>
                                    <div className="col-md-10 flex-wrap d-inline-flex">
                                      <div className="mr-3 my-1">
                                        <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                          <select
                                            className="form-control gray"
                                            value={item.sourceimagearn.value}
                                            onChange={(e) => {
                                              const value = e.target.value;

                                              setIsDirty(true);
                                              setFormData((state) =>
                                                update(state, {
                                                  imagepipelines: {
                                                    [idxItem]: {
                                                      sourceimagearn: {
                                                        value: {
                                                          $set: value,
                                                        },
                                                      },
                                                    },
                                                  },
                                                })
                                              );
                                            }}
                                          >
                                            <option value="">Select</option>
                                            {formData.images
                                              .filter((image) => {
                                                const selectedImages =
                                                  LOOKUP[
                                                    item.sourceimageos.value
                                                  ];

                                                if (selectedImages) {
                                                  return selectedImages.includes(
                                                    image.OsVersion
                                                  );
                                                } else {
                                                  return false;
                                                }
                                              })
                                              .map((sa, idxSa) => (
                                                <option
                                                  key={idxSa}
                                                  value={sa.Arn}
                                                >
                                                  {sa.Arn}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </div>
                                      <div className=" d-inline-flex formRow px-md-2 px-3 my-1 align-items-center justify-content-between">
                                        <div className="one form-line my-lg-0 my-1 d-inline-flex align-items-center">
                                          <label className="switch mb-0">
                                            <input
                                              type="checkbox"
                                              checked={item.sourceimagearnselection.value === "uselatest"}
                                              onChange={(e) => {
                                                const checked = e.target.checked;
                                                setIsDirty(true);
                                                setFormData((state) =>
                                                  update(state, {
                                                    imagepipelines: {
                                                      [idxItem]: {
                                                        sourceimagearnselection: {
                                                          value: {
                                                            $set: checked ? "uselatest" : "usethis",
                                                          },
                                                        },
                                                      },
                                                    },
                                                  })
                                                );
                                              }}
                                            />
                                            <span className="slider round"></span>
                                          </label>
                                          <span className="switchLabel">
                                          Always use the latest version of this
                                          </span>
                                        </div>
                                      </div>
                                      {/* <div className="d-inline-flex yes-no-area align-items-center">
                                        <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                                          Always use the latest version of this
                                        </div>
                                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                                          <label
                                            className={`createElementBtn btn btn-link btn-linkYes ${
                                              item.sourceimagearnselection
                                                .value === "uselatest" &&
                                              "active"
                                            } `}
                                          >
                                            <input
                                              type="radio"
                                              name="options"
                                              checked={
                                                item.sourceimagearnselection
                                                  .value === "uselatest"
                                              }
                                              onChange={() => {
                                                setIsDirty(true);
                                                setFormData((state) =>
                                                  update(state, {
                                                    imagepipelines: {
                                                      [idxItem]: {
                                                        sourceimagearnselection: {
                                                          value: {
                                                            $set: "uselatest",
                                                          },
                                                        },
                                                      },
                                                    },
                                                  })
                                                );
                                              }}
                                            />{" "}
                                            YES
                                          </label>
                                          <label
                                            className={`createElementBtn btn btn-link btn-linkNo ${
                                              item.sourceimagearnselection
                                                .value === "usethis" && "active"
                                            } `}
                                          >
                                            <input
                                              type="radio"
                                              name="options"
                                              checked={
                                                item.sourceimagearnselection
                                                  .value === "usethis"
                                              }
                                              onChange={() => {
                                                setIsDirty(true);
                                                setFormData((state) =>
                                                  update(state, {
                                                    imagepipelines: {
                                                      [idxItem]: {
                                                        sourceimagearnselection: {
                                                          value: {
                                                            $set: "usethis",
                                                          },
                                                        },
                                                      },
                                                    },
                                                  })
                                                );
                                              }}
                                            />{" "}
                                            NO
                                          </label>
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <div className=" row  px-md-2 px-0 align-items-center">
                                <div className="col-12  my-1">
                                  <p className="m-0 p-0 font-12">
                                    Installation Packages &amp; Security
                                    Hardening
                                  </p>
                                </div>

                                {formData.buildcomponents_componentslist.map(
                                  (comp, idxComp) => (
                                    <div
                                      key={idxComp}
                                      className="col-lg-3 col-md-6 my-1 col-6 form-line d-inline-flex align-items-center "
                                    >
                                      <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                        <label className="switch mb-0 ml-0">
                                          <input
                                            type="checkbox"
                                            checked={
                                              item.componentslist.indexOf(
                                                comp
                                              ) !== -1
                                            }
                                            onChange={() => {
                                              setIsDirty(true);

                                              if (
                                                item.componentslist.indexOf(
                                                  comp
                                                ) !== -1
                                              ) {
                                                setFormData((state) =>
                                                  update(state, {
                                                    imagepipelines: {
                                                      [idxItem]: {
                                                        componentslist: {
                                                          $splice: [
                                                            [
                                                              state.imagepipelines[
                                                                idxItem
                                                              ].componentslist.indexOf(
                                                                comp
                                                              ),
                                                              1,
                                                            ],
                                                          ],
                                                        },
                                                      },
                                                    },
                                                  })
                                                );
                                              } else {
                                                setFormData((state) =>
                                                  update(state, {
                                                    imagepipelines: {
                                                      [idxItem]: {
                                                        componentslist: {
                                                          $push: [comp],
                                                        },
                                                      },
                                                    },
                                                  })
                                                );
                                              }
                                            }}
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                        <span className="switchLabel ">
                                          {components_lookup[comp]}
                                        </span>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>

                            <div className="d-flex px-1 my-1 align-items-center">
                              <div className="d-inline-flex yes-no-area align-items-center">
                                <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                                  Share AMI with all accounts in the
                                  organization
                                </div>
                                <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                                  <label
                                    className={`createElementBtn btn btn-link btn-linkYes ${
                                      item.shareami.value === "yes" && "active"
                                    } `}
                                  >
                                    <input
                                      type="radio"
                                      name="options"
                                      checked={item.shareami.value === "yes"}
                                      onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            imagepipelines: {
                                              [idxItem]: {
                                                shareami: {
                                                  value: { $set: "yes" },
                                                },
                                              },
                                            },
                                          })
                                        );
                                      }}
                                    />{" "}
                                    YES
                                  </label>
                                  <label
                                    className={`createElementBtn btn btn-link btn-linkNo ${
                                      item.shareami.value === "no" && "active"
                                    } `}
                                  >
                                    <input
                                      type="radio"
                                      name="options"
                                      checked={item.shareami.value === "no"}
                                      onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                          update(state, {
                                            imagepipelines: {
                                              [idxItem]: {
                                                shareami: {
                                                  value: { $set: "no" },
                                                },
                                              },
                                            },
                                          })
                                        );
                                      }}
                                    />{" "}
                                    NO
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                      <div className="d-inline-flex w-100 justify-content-between flex-wrap">
                        <div className="d-inline-flex flex-wrap">
                          <div className="d-flex align-items-center bg-special-lightblue py-2 px-2 mr-3 my-1 border-10">
                            <div className="d-inline-flex yes-no-area align-items-center">
                              <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                                Deploy AMI Compliance Check
                              </div>
                              <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center  mr-0">
                                <RadioGroup
                                  field="deployamicompliancecheck"
                                  value={
                                    formData.deployamicompliancecheck.value
                                  }
                                  onChange={updateRadio}
                                ></RadioGroup>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex  align-items-center bg-special-lightblue py-2 px-2 my-1 border-10">
                            <div className="d-inline-flex yes-no-area align-items-center">
                              <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                                Deploy Continuous AMI Vulnerability Assessments
                              </div>
                              <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                                <RadioGroup
                                  field="deploycontinuousamivulnerabilityassessments"
                                  value={
                                    formData
                                      .deploycontinuousamivulnerabilityassessments
                                      .value
                                  }
                                  onChange={updateRadio}
                                ></RadioGroup>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="add-button d-flex align-items-center">
                          <button
                            disabled={disabled}
                            type="button"
                            onClick={addImagepipeline}
                            className="add-new-account-link my-1 btn-animation d-inline-flex align-items-center"
                          >
                            <p className="my-0 mr-2 p-0 green-style2">
                              Add Image Pipeline
                            </p>
                            <img src="../images/coloredPlus.svg" />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.INFRASTRUCTURE_ADIntegration + "/" + projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Active Directory Integration
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.INFRASTRUCTURE_PATCHMANAGEMENT + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Patch Management
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageManagement;
