import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import { validateAccountEmailId } from "../../../../helpers/validator";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/monitoring/cloudwatchevents";
const EMAIL_IDS = [
  {key: "accountframeworkemailid", name: "Account Framework Email Id",email: "account@client.com"},
  {key: "iamemailid", name: "IAM Email Id", email: "iam@client.com"},
  {key: "networkemailid", name: "Network Email Id", email: "network@client.com"},
  {key: "securityemailid", name: "Security Email Id", email: "security@client.com"},
  {key: "infrastructureemailid", name: "Infrastructure Email Id", email: "infrastructure@client.com"},
  {key: "governanceemailid", name: "Governance Email Id", email:"governance@client.com"},
  {key: "monitoringemailid", name: "Monitoring Email Id", email:"monitoring@client.com"},
  {key: "dataprotectionemailid", name: "Data Protection Email Id", email: "dataprotection@client.com"},
  {key: "digitalemailid", name: "Digital Email Id",email:"digital@client.com"},
];

const getInitialFormData = (data) => {
  const initialFormData = {
    deploybaselinecloudwatcheventrules: {
      value: data[`${PREFIX}/deploybaselinecloudwatcheventrules`] || "yes",
    },
    monitorcrossaccountassumerole: {
      value:  data[`${PREFIX}/monitorcrossaccountassumerole`] || "yes",
    }
  };

  EMAIL_IDS.forEach(emailId => {
    initialFormData[`domainnotifications/${emailId.key}`] = {
      value: data[`${PREFIX}/domainnotifications/${emailId.key}`] || "",
      isValid: data[`${PREFIX}/domainnotifications/${emailId.key}`] ? true : false,
    }
  });


  return initialFormData;
};

const CloudwatchEvents = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));
  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);
  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        setEmpty(result.data.empty);

        setFormData(convertBEToFE(result.data));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploybaselinecloudwatcheventrules`]: formData
        .deploybaselinecloudwatcheventrules.value,
    };

    EMAIL_IDS.forEach(emailId => {
      if (formData[`domainnotifications/${emailId.key}`].isValid) {
        be[`${PREFIX}/domainnotifications/${emailId.key}`] = formData[`domainnotifications/${emailId.key}`].value;
      }
    });

    if(formData.deploybaselinecloudwatcheventrules.value === "yes")
    {
      be[`${PREFIX}/monitorcrossaccountassumerole`] = formData.monitorcrossaccountassumerole.value;
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const onFieldChange = (value, key) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (validateFunc, value, key) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          [key]: { isValid: { $set: errorMsg === undefined } },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, { [key]: { isValid: { $set: false } } })
      );
    }
  };


  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Monitoring</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Monitoring"
                isDirty={isDirty}
                //dashboardRoute={ROUTES.GOVERNANCE_SERVICE_CONTROL}
                designRoute={ROUTES.DESIGN_MONITORING}
              ></DomainMenu>
              <fieldset
                disabled={disabled}
                className="mainArea fitDeviceHeight"
              >
                <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <div className="row d-flex align-items-center">
                    <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                      <h3>CloudWatch Event Rules</h3>
                    </div>
                    <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                      <div className="d-inline-flex yes-no-area align-items-center">
                        <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                          Deploy Baseline CloudWatch Event Rules
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                          <label
                            className={`createElementBtn btn btn-link btn-linkYes mw-70 ${
                              formData.deploybaselinecloudwatcheventrules
                                .value === "yes" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.deploybaselinecloudwatcheventrules
                                  .value === "yes"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    deploybaselinecloudwatcheventrules: {
                                      value: { $set: "yes" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            YES
                          </label>
                          <label
                            className={`dontCreateElementBtn btn btn-link btn-linkNo mw-70 ${
                              formData.deploybaselinecloudwatcheventrules
                                .value === "no" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.deploybaselinecloudwatcheventrules
                                  .value === "no"
                              }
                            />{" "}
                            NO
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {empty && <NoInitialData />}

                  <div className="mainContentArea  row px-2 py-1 my-1 d-flex">
                    {EMAIL_IDS.map((emailId, idx) => (
                      <div
                        key={idx}
                        className="d-inline-flex align-items-center formRow"
                      >
                        <div className="col-md-3 my-1 p-0">
                          <span className="mb-0 font-12">{emailId.name}</span>
                        </div>
                        <div className="col-md-9 flex-wrap d-inline-flex">
                          <div className="mr-3 my-1">
                            <div className="w-250 bg-gray border-10 p-relative d-inline-flex align-items-center">
                              <input
                                type="text"
                                placeholder={emailId.email}
                                value={
                                  formData[`domainnotifications/${emailId.key}`]
                                    .value
                                }
                                className={`form-control ${
                                  formData[`domainnotifications/${emailId.key}`]
                                    .value !== "" &&
                                  formData[`domainnotifications/${emailId.key}`]
                                    .isValid === false
                                    ? "invalid"
                                    : ""
                                }`}
                                onChange={(e) =>
                                  onFieldChange(
                                    e.target.value,
                                    `domainnotifications/${emailId.key}`
                                  )
                                }
                                onBlur={(e) =>
                                  onFieldBlur(
                                    validateAccountEmailId,
                                    e.target.value,
                                    `domainnotifications/${emailId.key}`
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="w-100 px-2 py-1 my-1 row">
                          <div className="one form-line d-inline-flex align-items-center">
                            <label className="switch mb-0">
                              <input
                                type="checkbox"
                                checked={
                                  formData.monitorcrossaccountassumerole
                                    .value === "yes"
                                }
                                onChange={(e) =>
                                  updateRadio(
                                    "monitorcrossaccountassumerole",
                                    e.target.checked ? "yes" : "no"
                                  )
                                }
                              />
                              <span className="slider round"></span>
                            </label>
                            <span className="switchLabel">
                            Monitor cross-account assume role access
                            </span>
                          </div>
                        </div>
                     
                </div>

                {console.log(formData)}
              </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.MONITORING_CENTRALIZED_LOGGING + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Centralized Logging
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CloudwatchEvents;
