import React, { useEffect, useState } from "react";
import useSession from "../../hooks/useSession";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import * as ROUTES from "../../constants/routes";
import $ from "jquery";
import useAwsAmplify from "../../hooks/useAwsAmplify";
import { getError } from "../../helpers/errorHelper";
import {
  getConfigureRoute,
  getDefaultRoute,
  getPrelaunchRoute,
} from "../../helpers/projectHelper";

const PRIMARYREGION_PREFIX = "/network/aws/awsprimaryregion";

const DomainMenu = ({
  title = "Account Framework",
  isDirty = false,
  configureRoute,
  isConfigure = true,
  dashboardRoute,
  isDashboard = false,
  designRoute,
  isDesign = false,
  saveDataIfDirty,
  isPrelaunch = false,
}) => {
  const { projectId } = useParams();
  const location = useLocation();

  const { authUser, isUser, isClientAdmin, isSuperAdmin, group } = useSession();
  const history = useHistory();

  const awsAmplify = useAwsAmplify();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [projectName, setProjectName] = useState("My Projects");
  const [clientName, setClientName] = useState("");
  const [projectType, setProjectType] = useState("");

  const [myProjectsData, setMyProjectsData] = useState({
    projects: [],
    clientName: "",
  });
  const [hasDocAccess, setHasDocAccess] = useState(false);
  const [hasBuildAccess, setHasBuildAccess] = useState(false);
  const [hasDashboardAccess, setHasDashboardAccess] = useState(false);
  const [hasLaunchAccess, setHasLaunchAccess] = useState(false);
  const [executionARN, setExecutionARN] = useState();
  const [primaryRegion, setPrimaryRegion] = useState();

  const [toggleLinktodrop, setToggleLinktodrop] = useState(false);

  /* useEffect(() => {
    $(".navLinkToDrop").click(function () {
      $(this).find(".drop").toggleClass("nonclosed");
    });
  }); */

  useEffect(() => {
    const getMyProjects = async () => {
      try {
        setError(null);
        setIsLoading(true);

        const results = await awsAmplify.getMyProjects();

        setIsLoading(false);

        console.log(results);

        if (results.data && results.data.getMyProjects) {
          setMyProjectsData(results.data.getMyProjects);
        }
      } catch (err) {
        setIsLoading(false);

        getError(err, setError);
      }
    };

    if (authUser) {
      getMyProjects();
    }
  }, [authUser]);

  useEffect(() => {
    const getProjectAccess = async (projectId) => {
      try {
        setError(null);
        setIsLoading(true);

        const results = await awsAmplify.getProjectAccess(projectId);

        const primaryRegionResults = await awsAmplify.loadProjectData(
          projectId,
          PRIMARYREGION_PREFIX
        );

        setIsLoading(false);

        console.log(results);

        if (results.data && results.data.getProjectAccess) {
          setHasBuildAccess(results.data.getProjectAccess.hasBuildAccess);
          setHasDashboardAccess(
            results.data.getProjectAccess.hasDashboardAccess
          );
          setHasLaunchAccess(results.data.getProjectAccess.hasLaunchAccess);
          setHasDocAccess(results.data.getProjectAccess.hasDocAccess);
          setExecutionARN(results.data.getProjectAccess.executionARN);
        }

        if (primaryRegionResults.data) {
          setPrimaryRegion(primaryRegionResults.data[PRIMARYREGION_PREFIX]);
        }
      } catch (err) {
        setIsLoading(false);

        const errorMsg = getError(err, setError);

        if (errorMsg === "You have no access") {
          history.goBack();
        }
      }
    };

    if (projectId) {
      const project = myProjectsData.projects.find(
        (p) => p.ProjectID === projectId
      );

      if (project) {
        setProjectName(project.ProjectName);
        setClientName(project.ClientName);
        setProjectType(project.ProjectType);
      }

      getProjectAccess(projectId);
    }
  }, [projectId, myProjectsData]);

  const getBuildStatusExternalUrl = () => {
    return `https://console.aws.amazon.com/states/home?region=${primaryRegion}#/executions/details/${executionARN}`;
  };

  return (
    <>
      {!authUser ? null : (
        <>
          {error && (
            <div className="alert-area">
              <div className="redalert">
                <div className="warnImg">
                  <img alt="" src="../images/warning.svg" />
                </div>
                <span className="closebtn" onClick={() => setError(null)}>
                  &times;
                </span>
                <div className="messageText">
                  <strong>Error!</strong> {error}
                </div>
              </div>
            </div>
          )}

          {/* {isLoading && (
            <div className="alert-area">
              <div className="bluealert">
                <div className="messageText">Please wait...</div>
              </div>
            </div>
          )} */}
          <div className="topInfoNav d-inline-flex justify-content-between align-items-start ">
            <ul className=" d-inline-flex leftNav">
              <li className="">
                <a className="text-nowrap">{title}</a>
              </li>
              <li className="accountName">
                <div>
                  {projectType && (
                    <img
                      style={{ marginRight: "5px" }}
                      width="35"
                      height="35"
                      alt=""
                      src={`../images/cloud/${projectType.toLowerCase()}.png`}
                    />
                  )}
                </div>
                <p className="">{clientName}</p>
              </li>
              <li
                className="linkToDrop navLinkToDrop"
                onClick={() => setToggleLinktodrop(!toggleLinktodrop)}
              >
                <button
                  className="pr-2 d-inline-flex selectedProject text-nowrap justify-content-center align-items-center"
                  type="button"
                >
                  {projectName}{" "}
                  <div className="iconDrop">
                    <img alt="" src="../images/small-down.svg" />
                  </div>
                </button>
                <ul
                  className={
                    "drop m-0 px-2 py-0 " +
                    (toggleLinktodrop ? "nonclosed" : "")
                  }
                >
                  <div className="d-flex flex-column">
                    {myProjectsData.projects.map((project, idxProject) => (
                      <li key={idxProject}>
                        <button
                          type="button"
                          onClick={() => {
                            let route = "";
                            const locationArray = location.pathname.split("/");

                            if (locationArray.length === 3) {
                              if (project.ProjectType === projectType) {
                                route = `/${locationArray[1]}/${project.ProjectID}`;
                              } else {
                                if (
                                  [
                                    ROUTES.PRELAUNCH,
                                    ROUTES.GCP_PRELAUNCH,
                                    ROUTES.ALICLOUD_PRELAUNCH,
                                    ROUTES.AZURE_PRELAUNCH,
                                  ].includes(`/${locationArray[1]}`)
                                ) {
                                  route = `${getPrelaunchRoute(
                                    project.ProjectType
                                  )}/${project.ProjectID}`;
                                } else {
                                  route = `${getDefaultRoute(
                                    project.ProjectType
                                  )}/${project.ProjectID}`;
                                }
                              }
                            } else {
                              route = `${getDefaultRoute(
                                project.ProjectType
                              )}/${project.ProjectID}`;
                            }

                            console.log(route);

                            if (saveDataIfDirty) {
                              saveDataIfDirty(route);
                            } else {
                              history.push(route);
                            }
                          }}
                        >
                          {project.ClientName
                            ? `${project.ClientName} | ${project.ProjectName}`
                            : project.ProjectName}
                        </button>
                      </li>
                    ))}
                  </div>
                </ul>
              </li>
              {!isDashboard && !isPrelaunch && (
                <>
                  <div className="launch-save-icons-area">
                    <button
                      type="submit"
                      className={
                        isDirty
                          ? "mr-1 launch-save-icons save-icon"
                          : "mr-1 launch-save-icons save-icon disabled"
                      }
                      disabled={!isDirty}
                    ></button>
                    <button
                      type="button"
                      className={
                        "mr-1 launch-save-icons launch-icon " +
                        (!hasLaunchAccess ? "disabled" : "")
                      }
                      disabled={!hasLaunchAccess}
                      onClick={() => {
                        if (saveDataIfDirty) {
                          saveDataIfDirty(
                            `${getPrelaunchRoute(projectType)}/${projectId}`
                          );
                        } else {
                          history.push(
                            `${getPrelaunchRoute(projectType)}/${projectId}`
                          );
                        }
                      }}
                    ></button>
                  </div>
                </>
              )}
            </ul>
            <ul className="m-0 py-0 rightNav d-inline-flex px-0">
              <li>
                <span>PLAN</span>
              </li>
              <li>
                {isDesign ? (
                  <span style={{ color: "#B5B5B5" }}>DESIGN</span>
                ) : hasDocAccess ? (
                  <button
                    className="font-12 active"
                    type="button"
                    onClick={async () => {
                      if (saveDataIfDirty) {
                        await saveDataIfDirty();
                      }

                      history.push(designRoute + "/" + projectId);
                    }}
                  >
                    DESIGN
                  </button>
                ) : (
                  <span>DESIGN</span>
                )}
              </li>
              <li>
                <button
                  className="font-12 active"
                  type="button"
                  onClick={async () => {
                    if (saveDataIfDirty) {
                      await saveDataIfDirty();
                    }

                    history.push(
                      `${getConfigureRoute(projectType)}/${projectId}`
                    );
                  }}
                >
                  CONFIGURE
                </button>

                {/* {isConfigure ? (
                  <span className="active">CONFIGURE</span>
                ) : (
                  <button
                    className="font-12"
                    style={{ color: "#B5B5B5" }}
                    type="button"
                    onClick={async () => {
                      if (saveDataIfDirty) {
                        await saveDataIfDirty();
                      }
                      else {
                        history.push(
                          `${getConfigureRoute(projectType)}/${projectId}`
                        );
                      }
                    }}
                  >
                    CONFIGURE
                  </button>
                )} */}
              </li>
              <li>
                {hasBuildAccess ? (
                  <button
                    className="font-12 active"
                    type="button"
                    onClick={async () => {
                      if (saveDataIfDirty) {
                        await saveDataIfDirty();
                      }

                      const link = getBuildStatusExternalUrl();
                      const win = window.open(link, "_blank");
                      win.focus();
                    }}
                  >
                    BUILD
                  </button>
                ) : (
                  <span>BUILD</span>
                )}
              </li>
              <li>
                {isDashboard ? (
                  <span style={{ color: "#B5B5B5" }}>VALIDATE</span>
                ) : hasDashboardAccess ? (
                  <button
                    className="font-12 active"
                    type="button"
                    onClick={async () => {
                      if (saveDataIfDirty) {
                        await saveDataIfDirty();
                      }

                      history.push(dashboardRoute + "/" + projectId);
                    }}
                  >
                    VALIDATE
                  </button>
                ) : (
                  <span>VALIDATE</span>
                )}
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default DomainMenu;
