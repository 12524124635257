import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

import $ from "jquery";
import useAwsAmplify from "../../hooks/useAwsAmplify";

const NavigationButton = ({ saveDataIfDirty, route, btnText }) => {
  const history = useHistory();
  const { projectId } = useParams();

  if (projectId) {
    route += "/" + projectId;
  }

  return (
    <button
      onClick={() => {
        if (saveDataIfDirty) {
          saveDataIfDirty(route);
        } else {
          history.push(route);
        }
      }}
    >
      {btnText}
    </button>
  );
};

const LeftHandMenu = ({ saveDataIfDirty, domain, isDashboard = false }) => {
  const { projectId } = useParams();
  const awsAmplify = useAwsAmplify();

  const [projectType, setProjectType] = useState(null);

  useEffect(() => {
    $(".leftMenuShowIcon").click(function () {
      $(this).toggleClass("closeIcon");
      $(".sol-menu-alan").toggleClass("opened");
      $(".a-link-button").removeClass("opened");
    });
    $(".leftMenuShowIcon-mobil").click(function () {
      $(".leftMenuShowIcon").toggleClass("closeIcon");
      $(".sol-menu-alan").toggleClass("opened");
      $(".a-link-button").removeClass("opened");
    });
    $(".a-link-button").click(function () {
      $(".sol-menu-alan").addClass("opened");
      $(".leftMenuShowIcon").addClass("closeIcon");
      $(".a-link-button").removeClass("opened");
      $(this).addClass("opened");
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await awsAmplify.getProjectType(projectId);
      console.log(result);

      if(result && result.data){
        setProjectType(result.data.getProjectType);
      }
    };

    fetchData();
  }, [projectId]);

  return (
    <>
      <div className="leftMenuShowIcon-mobil">
        <div>
          <img alt="" src="../images/hrstick.svg" />
          <img alt="" src="../images/hrstick.svg" />
          <img alt="" src="../images/hrstick.svg" />
        </div>
      </div>
      <div className="sol-menu-alan h-100">
        <div className="d-inline-flex w-100 justify-content-between leftShowMenuIconArea">
          <div className="leftMenuShowIcon d-flex">
            <div>
              <img alt="" src="../images/hrstick.svg" />
              <img alt="" src="../images/hrstick.svg" />
              <img alt="" src="../images/hrstick.svg" />
            </div>
          </div>
          <div className="leftMainIcon">
            <img alt="" src="../images/mainLogoWhite.svg" />
          </div>
        </div>
        <ul className="sol-menu">
          <li className="a-link d-flex flex-column">
            <div
              className={`a-link-button ${
                domain === "accountframework" && "active"
              }`}
            >
              <button>
                <div className="ikon-alan mr-2 ">
                  <img alt="" src="../images/social.png" />
                </div>
                <span className="text-alan">Account Framework</span>
              </button>
            </div>
            <ul className="b-link">
              {projectType === "AWS" && !isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Enterprise Core Accounts"
                      route={ROUTES.ENTERPRISE_CORE_ACCOUNTS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Environment strategy"
                      route={ROUTES.ENVIRONMENT_STRATEGY}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Application Accounts"
                      route={ROUTES.APPLICATION_ACCOUNTS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Developer Sandbox Acc."
                      route={ROUTES.DEVELOPER_SANDBOX_ACCOUNTS}
                    ></NavigationButton>
                  </li>
                </>
              )}
              {projectType === "AWS" && isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      btnText="Organizational Units"
                      route={ROUTES.ORGANIZATIONAL_UNITS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Accounts"
                      route={ROUTES.ACCOUNTS}
                    ></NavigationButton>
                  </li>
                </>
              )}
              {projectType === "GCP" && !isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Enterprise Core Accounts"
                      route={ROUTES.GCP_ENTERPRISE_CORE_ACCOUNTS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Environment strategy"
                      route={ROUTES.GCP_ENVIRONMENT_STRATEGY}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Application Accounts"
                      route={ROUTES.GCP_APPLICATION_ACCOUNTS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Developer Sandbox Acc."
                      route={ROUTES.GCP_DEVELOPER_SANDBOX_ACCOUNTS}
                    ></NavigationButton>
                  </li>
                </>
              )}
              {projectType === "GCP" && isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      btnText="Organizational Units"
                      route={ROUTES.GCP_ORGANIZATIONAL_UNITS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Accounts"
                      route={ROUTES.GCP_ACCOUNTS}
                    ></NavigationButton>
                  </li>
                </>
              )}
              {projectType === "Azure" && !isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Enterprise Core Accounts"
                      route={ROUTES.AZURE_ENTERPRISE_CORE_ACCOUNTS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Environment strategy"
                      route={ROUTES.AZURE_ENVIRONMENT_STRATEGY}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Application Accounts"
                      route={ROUTES.AZURE_APPLICATION_ACCOUNTS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Developer Sandbox Acc."
                      route={ROUTES.AZURE_DEVELOPER_SANDBOX_ACCOUNTS}
                    ></NavigationButton>
                  </li>
                </>
              )}
              {projectType === "Azure" && isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      btnText="Organizational Units"
                      route={ROUTES.AZURE_ORGANIZATIONAL_UNITS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Accounts"
                      route={ROUTES.AZURE_ACCOUNTS}
                    ></NavigationButton>
                  </li>
                </>
              )}
              {projectType === "AliCloud" && !isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Enterprise Core Accounts"
                      route={ROUTES.ALICLOUD_ENTERPRISE_CORE_ACCOUNTS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Environment strategy"
                      route={ROUTES.ALICLOUD_ENVIRONMENT_STRATEGY}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Application Accounts"
                      route={ROUTES.ALICLOUD_APPLICATION_ACCOUNTS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Developer Sandbox Acc."
                      route={ROUTES.ALICLOUD_DEVELOPER_SANDBOX_ACCOUNTS}
                    ></NavigationButton>
                  </li>
                </>
              )}
              {projectType === "AliCloud" && isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      btnText="Organizational Units"
                      route={ROUTES.ALICLOUD_ORGANIZATIONAL_UNITS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Accounts"
                      route={ROUTES.ALICLOUD_ACCOUNTS}
                    ></NavigationButton>
                  </li>
                </>
              )}
            </ul>
          </li>
          <li className="a-link d-flex flex-column">
            <div className={`a-link-button ${domain === "iam" && "active"}`}>
              <button>
                <div className="ikon-alan mr-2 ">
                  <img alt="" src="../images/business.svg" />
                </div>
                <span className="text-alan">Identity &amp; Access</span>
              </button>
            </div>
            <ul className="b-link">
              {projectType === "AWS" && !isDashboard && (
                <>
                {/*   <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Okta Integration"
                      route={ROUTES.OKTA_INTEGRATION}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="ADFS Integration"
                      route={ROUTES.ADFS_INTEGRATION}
                    ></NavigationButton>
                  </li> */}
                {/*   <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="AWS SSO Integration"
                      route={ROUTES.AWS_SSO_INTEGRATION}
                    ></NavigationButton>
                  </li> */}
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Baseline Roles"
                      route={ROUTES.BASELINE_ROLES}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Single Sign-On"
                      route={ROUTES.SINGLE_SIGN_ON}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Privileged Access"
                      route={ROUTES.PRIVILEGED_ACCESS_MANAGEMENT}
                    ></NavigationButton>
                  </li>
                </>
              )}
              {projectType === "AWS" && isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      btnText="Identity Providers"
                      route={ROUTES.IDENTITY_PROVIDERS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="IAM Roles"
                      route={ROUTES.IAM_ROLES}
                    ></NavigationButton>
                  </li>
                </>
              )}
            </ul>
          </li>
          <li className="a-link d-flex flex-column">
            <div
              className={`a-link-button ${domain === "network" && "active"}`}
            >
              <button>
                <div className="ikon-alan mr-2 ">
                  <img alt="" src="../images/symbol.svg" />
                </div>
                <span className="text-alan">Network</span>
              </button>
            </div>
            <ul className="b-link">
              {projectType === "AWS" && !isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Data Centers"
                      route={ROUTES.DATA_CENTERS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="AWS Environment"
                      route={ROUTES.AWS_ENVIRONMENT}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="VPC Design"
                      route={ROUTES.VPC_DESIGN}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="VPC Factory"
                      route={ROUTES.VPC_FACTORY}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Inter-VPC Connectivity"
                      route={ROUTES.INTER_VPC_CONNECTIVITY}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Cross-region Connectivity"
                      route={ROUTES.CROSS_REGION_CONNECTIVITY}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="VPN Connectivity"
                      route={ROUTES.VPC_CONNECTIVITY}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="DirectConnect"
                      route={ROUTES.DIRECT_CONNECT_CONNECTIVITY}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Traffic Inspection"
                      route={ROUTES.TRAFFIC_INSPECTION}
                    ></NavigationButton>
                  </li>
                  {/* <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Egress Services"
                      route={ROUTES.EGRESS_SERVICES}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Ingress Filtering"
                      route={ROUTES.INGRESS_SERVICES}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Inline Services"
                      route={ROUTES.INLINE_SERVICES}
                    ></NavigationButton>
                  </li> */}
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="VPC Endpoints"
                      route={ROUTES.VPC_SHAREDENDPOINTS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Private DNS"
                      route={ROUTES.INFRASTRUCTURE_PRIVATEDNS}
                    ></NavigationButton>
                  </li>
                </>
              )}
              {projectType === "AWS" && isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      btnText="VPCs"
                      route={ROUTES.VPC}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Transit Gateway"
                      route={ROUTES.TGW}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Hybrid Network"
                      route={ROUTES.VPN}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Network Firewall"
                      route={ROUTES.NETWORK_FIREWALL_FIREWALLS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Traffic Mirror Targets"
                      route={ROUTES.TRAFFIC_MIRROR_TARGETS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Resource Shares"
                      route={ROUTES.RESOURCE_SHARES_BY_ME}
                    ></NavigationButton>
                  </li>
                </>
              )}
            </ul>
          </li>
          <li className="a-link d-flex flex-column">
            <div
              className={`a-link-button ${domain === "security" && "active"}`}
            >
              <button>
                <div className="ikon-alan mr-2 ">
                  <img alt="" src="../images/computer.svg" />
                </div>
                <span className="text-alan">Security</span>
              </button>
            </div>
            <ul className="b-link">
              {projectType === "AWS" && !isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Compliance Management"
                      route={ROUTES.SECURITY_COMPLIANCE_MANAGEMENT}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Intelligent Threat Detection"
                      route={ROUTES.SECURITY_INTELLIGENT_THREAT_DETECTION}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Vulnerability Management"
                      route={ROUTES.SECURITY_VULNERABILITY_MANAGEMENT}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Endpoint Protection"
                      route={ROUTES.SECURITY_ENDPOINT_PROTECTION}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Secrets Management"
                      route={ROUTES.SECURITY_SECRETS_MANAGEMENT}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Perimeter Protection"
                      route={ROUTES.SECURITY_PERIMETER_PROTECTION}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="SIEM"
                      route={ROUTES.SECURITY_SIEM}
                    ></NavigationButton>
                  </li>
                </>
              )}
              {projectType === "AWS" && isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      btnText="Security Hub"
                      route={ROUTES.SECURITY_HUB_MEMBERS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Guard Duty"
                      route={ROUTES.SECURITY_GUARDDUTY_MEMBERS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Secrets Management"
                      route={ROUTES.SECURITY_SECRETS_MANAGER}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Firewall Manager"
                      route={ROUTES.SECURITY_FIREWALL_SECURITY_POLICIES}
                    ></NavigationButton>
                  </li>
                </>
              )}
            </ul>
          </li>
          <li className="a-link d-flex flex-column">
            <div
              className={`a-link-button ${
                domain === "infrastructure" && "active"
              }`}
            >
              <button>
                <div className="ikon-alan mr-2 ">
                  <img alt="" src="../images/line.svg" />
                </div>
                <span className="text-alan">Infrastructure</span>
              </button>
            </div>
            <ul className="b-link">
              {projectType === "AWS" && !isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Active Directory Integration"
                      route={ROUTES.INFRASTRUCTURE_ADIntegration}
                    ></NavigationButton>
                  </li>

                  <li>
                    <NavigationButton
                      btnText="Image Management"
                      saveDataIfDirty={saveDataIfDirty}
                      route={ROUTES.INFRASTRUCTURE_IMAGEMANAGEMENT}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Patch Management"
                      saveDataIfDirty={saveDataIfDirty}
                      route={ROUTES.INFRASTRUCTURE_PATCHMANAGEMENT}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="VDI Solution"
                      saveDataIfDirty={saveDataIfDirty}
                      route={ROUTES.INFRASTRUCTURE_VDISOLUTION}
                    ></NavigationButton>
                  </li>
                <li>
                    <NavigationButton
                      btnText="Container Platform"
                      saveDataIfDirty={saveDataIfDirty}
                      route={ROUTES.INFRASTRUCTURE_CONTAINER_PLATFORM}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="API Gateway"
                      saveDataIfDirty={saveDataIfDirty}
                      route={ROUTES.INFRASTRUCTURE_API_GATEWAY}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Shared File System"
                      saveDataIfDirty={saveDataIfDirty}
                      route={ROUTES.INFRASTRUCTURE_SHARED_FILE_SYSTEM}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Secure File Exchange"
                      saveDataIfDirty={saveDataIfDirty}
                      route={ROUTES.INFRASTRUCTURE_SECURE_FILE_EXCHANGE}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Enterprise Service Bus"
                      saveDataIfDirty={saveDataIfDirty}
                      route={ROUTES.INFRASTRUCTURE_ENTERPRISE_SERVICE_BUS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Message Queue"
                      saveDataIfDirty={saveDataIfDirty}
                      route={ROUTES.INFRASTRUCTURE_MESSAGE_QUEUE}
                    ></NavigationButton>
                  </li>
                  <li>
                  <NavigationButton
                      btnText="Data Lake"
                      saveDataIfDirty={saveDataIfDirty}
                      route={ROUTES.INFRASTRUCTURE_DATA_LAKE}
                    ></NavigationButton>
                 </li> 
                </>
              )}
              {projectType === "AWS" && isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      btnText="Directories"
                      route={ROUTES.INFRASTRUCTURE_DIRECTORIES}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Private DNS"
                      route={ROUTES.INFRASTRUCTURE_INBOUND_ENDPOINTS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Image Builder"
                      route={ROUTES.INFRASTRUCTURE_COMPONENTS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Patch Management"
                      route={ROUTES.INFRASTRUCTURE_BASELINES}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Workspaces"
                      route={ROUTES.INFRASTRUCTURE_WORKSPACES}
                    ></NavigationButton>
                  </li>
                </>
              )}
            </ul>
          </li>
          <li className="a-link d-flex flex-column">
            <div
              className={`a-link-button ${domain === "governance" && "active"}`}
            >
              <button>
                <div className="ikon-alan mr-2 ">
                  <img alt="" src="../images/gaming.svg" />
                </div>
                <span className="text-alan">Governance</span>
              </button>
            </div>
            <ul className="b-link">
              {projectType === "AWS" && !isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Tagging Strategy"
                      route={ROUTES.GOVERNANCE_TAGGING_STRATEGY}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Service Control Policies"
                      route={ROUTES.GOVERNANCE_SERVICE_CONTROL_POLICIES}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Config Rules"
                      route={ROUTES.GOVERNANCE_CONFIG_RULES}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Budget Enforcement"
                      route={ROUTES.GOVERNANCE_BUDGET_ENFORCEMENT}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="AWS Limits Monitor"
                      route={ROUTES.GOVERNANCE_AWS_LIMITS_MONITOR}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="ServiceNow Integration"
                      route={ROUTES.GOVERNANCE_SERVICENOW_INTEGRATION}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="FinOps"
                      route={ROUTES.GOVERNANCE_FIN_OPS}
                    ></NavigationButton>
                  </li>
                </>
              )}
              {projectType === "AWS" && isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      btnText="Organization Policies"
                      route={ROUTES.GOVERNANCE_SERVICE_CONTROL}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Config"
                      route={ROUTES.GOVERNANCE_RECORDERS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="AWS Budgets"
                      route={ROUTES.GOVERNANCE_AWS_BUDGETS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      btnText="Service Catalog"
                      route={ROUTES.GOVERNANCE_PORTFOLIOS}
                    ></NavigationButton>
                  </li>
                </>
              )}
            </ul>
          </li>
          <li className="a-link d-flex flex-column">
            <div
              className={`a-link-button ${domain === "monitoring" && "active"}`}
            >
              <button>
                <div className="ikon-alan mr-2 ">
                  <img alt="" src="../images/gaming.svg" />
                </div>
                <span className="text-alan">Monitoring</span>
              </button>
            </div>
            <ul className="b-link">
              {projectType === "AWS" && !isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="CloudWatch Rules"
                      route={ROUTES.MONITORING_CLOUDWATCH_EVENTS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Centralized Logging"
                      route={ROUTES.MONITORING_CENTRALIZED_LOGGING}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Log Analytics"
                      route={ROUTES.MONITORING_LOG_ANALYTICS}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Monitoring Tools Integration"
                      route={ROUTES.MONITORING_MONITORING_TOOLS_INTEGRATION}
                    ></NavigationButton>
                  </li>
                </>
              )}
            </ul>
          </li>
          <li className="a-link d-flex flex-column">
            <div
              className={`a-link-button ${
                domain === "dataprotection" && "active"
              }`}
            >
              <button>
                <div className="ikon-alan mr-2 ">
                  <img alt="" src="../images/computer%20(1).svg" />
                </div>
                <span className="text-alan">Data Protection</span>
              </button>
            </div>
            <ul className="b-link">
              {projectType === "AWS" && isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Backup & Archival"
                      route={ROUTES.DATA_PROTECTION_BACKUP_POLICY}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Certificate Management"
                      route={ROUTES.DATA_PROTECTION_ACM}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Key Management"
                      route={ROUTES.DATA_PROTECTION_KEYS}
                    ></NavigationButton>
                  </li>
                </>
              )}
              {projectType === "AWS" && !isDashboard && (
                <>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Backup & Archival"
                      route={ROUTES.DATA_PROTECTION_BACKUP_ARCHIVAL}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Certificate Management"
                      route={ROUTES.DATA_PROTECTION_CERTIFICATE_MANAGEMENT}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Key Management"
                      route={ROUTES.DATA_PROTECTION_KEY_MANAGEMENT}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Data Privacy"
                      route={ROUTES.DATA_PROTECTION_DATA_PRIVACY}
                    ></NavigationButton>
                  </li>
                  <li>
                    <NavigationButton
                      saveDataIfDirty={saveDataIfDirty}
                      btnText="Data Loss Prevention"
                      route={ROUTES.DATA_PROTECTION_DLP}
                    ></NavigationButton>
                  </li>
                </>
              )}
            </ul>
          </li>

          <li className="a-link d-flex flex-column">
            <div className="a-link-button">
              <a>
                <div className="ikon-alan mr-2 ">
                  <img alt="" src="../images/website.svg" />
                </div>
                <span className="text-alan">Digital</span>
              </a>
            </div>
            <ul className="b-link">
              {projectType === "AWS" && !isDashboard && (
                <>
                <li>
                  <NavigationButton
                        saveDataIfDirty={saveDataIfDirty}
                        btnText="Developer Portal"
                        route={ROUTES.DIGITAL_DEV_PORTAL}
                  ></NavigationButton>
                 </li>
                  <li>
                  <NavigationButton
                        saveDataIfDirty={saveDataIfDirty}
                        btnText="Developer Tools"
                        route={ROUTES.DIGITAL_DEV_TOOLS}
                  ></NavigationButton>
                  </li>
                  <li>
                  <NavigationButton
                        saveDataIfDirty={saveDataIfDirty}
                        btnText="Starter Kits"
                        route={ROUTES.DIGITAL_STARTER_KITS}
                  ></NavigationButton>
                  </li>
               </>
             )}
              
              
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LeftHandMenu;
