import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../Dashboard";
import * as ROUTES from "../../../../constants/routes";

//const projectId = "google_1";
const dashboardName = "IAM Roles";

const Details = ({ close, selectedRow }) => {
  const [type, setType] = useState("details");

  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "details" && "my_active"
              }`}
              onClick={() => setType("details")}
            >
              Details
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "permissionPolicies" && "my_active"
              }`}
              onClick={() => setType("permissionPolicies")}
            >
              Permission Policies
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "trustRelationships" && "my_active"
              }`}
              onClick={() => setType("trustRelationships")}
            >
              Trust Relationships
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "details" && "inaktif"
            }`}
          >
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Account ID</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Account}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">RoleName</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.RoleName}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Description</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Description}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Path</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Path}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Role Id</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.RoleId}
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">ARN</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Arn}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Max Session Duration
                </p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.MaxSessionDuration}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Last Activity</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.RoleLastUsed &&
                    selectedRow.RoleLastUsed.LastUsedDate}
                </p>
              </div>
            </div>
          </div>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "permissionPolicies" && "inaktif"
            }`}
          >
            <div className="col-12">
              <p className="color-spc3">
                <pre className="font-12">
                  {JSON.stringify(selectedRow.AttachedPolicies, null, 2)}
                </pre>
              </p>
            </div>
          </div>
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "trustRelationships" && "inaktif"
            }`}
          >
            <div className="col-12">
              <p className="color-spc3">
                <pre className="font-12">
                  {JSON.stringify(
                    selectedRow.AssumeRolePolicyDocument,
                    null,
                    2
                  )}
                </pre>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams();
  return (
    <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.IDENTITY_PROVIDERS + "/" + projectId}
    >
      <img src="../images/ribbon-design.svg" />
      <p className="m-0 p-0 ml-2 font-weight-bold">Identity Providers</p>
    </Link>
  );
};

const IamRoles = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "Account",
        //width: 35,
      },
      {
        Header: "RoleName",
        accessor: "RoleName",
        width: 400,
      },
      {
        Header: "Description",
        accessor: "Description",
        width: 250,
      },
      {
        Header: "Path",
        accessor: "Path",
        width: 60,
      },
      {
        Header: "Role Id",
        accessor: "RoleId",
        width: 200,
      },
      {
        Header: "ARN",
        accessor: "Arn",
        width: 550,
      },
      {
        Header: "Creation Date",
        accessor: "CreateDate",
        //width: 60,
      },
      {
        Header: "Max Session Duration",
        accessor: "MaxSessionDuration",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "iam",
    title: "Identity & Access",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.SINGLE_SIGN_ON,
    designRoute: ROUTES.DESIGN_IAM
  });
};

export default IamRoles;
